const WebViewManager = async (data = undefined, setModal, setSubModal) => {
    if (data !== undefined) {
        const WebApp = data;
        if (WebApp.type === "WEBAPP_ACTION") {
            switch (WebApp.title) {
                case "ACTION_SUBMODAL":
                    switch (WebApp.data) {
                        case "submodal_transaction_history":
                            setSubModal(true, "submodal_trans_history");
                            break;
                        case "submodal_change_password":
                            setSubModal(true, "submodal_change_pass");
                            break;
                        case "submodal_contact_us":
                            setSubModal(true, "submodal_contact_us");
                            break;
                        case "submodal_topup":
                            setSubModal(true, "submodal_payment_method");
                            break;
                        case "submodal_close":
                            setSubModal(false, "");
                            break;
                        default:
                            // Optional: handle any default case here if needed
                            break;
                    }
                case "ACTION_MODAL":
                    switch (WebApp.data) {
                        case "modal_close":
                            setModal(false, "");
                            break;
                        default:
                            break;
                    }

                default:
                    break;
            }
        }
        window.ReactNativeWebView.postMessage(
            JSON.stringify({
                type: "WEBAPP_ACTION",
                message: JSON.stringify(data),
            })
        )
    }
    // setSubModal(true, "submodal_trans_history")
    window.ReactNativeWebView.postMessage(
        JSON.stringify({
            type: "WEBAPP_LOGS",
            message: "WELCOMEEEEEEEEEEEEEEEEEEEEEEEEEEEEE to PNC! -website",
        })
    )
}

export default WebViewManager;