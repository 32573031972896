import React, { useState, useContext } from "react";
import OtpInput from "react-otp-input";

import { renderTheme } from "../../../../App";

const OTPField = (props) => {
	const { isMobile } = useContext(renderTheme);

	return (
		<OtpInput
			value={props.otp}
			onChange={props.setOtp}
			numInputs={6}
			renderSeparator={<span>&nbsp;</span>}
			renderInput={(props) => <input {...props} />}
			inputStyle={{
				fontSize: isMobile ? "20px" : "32px",
				width: isMobile ? "48px" : "64px",
				height: isMobile ? "64px" : "80px",
				borderRadius: "8px",
				borderColor: "#1a6fff",
			}}
			containerStyle={{ width: "100%", display: "flex", justifyContent: "center" }}
			inputType='tel'
		/>
	);
};

export default OTPField;
