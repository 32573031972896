import { useState, useEffect, useRef } from "react";
import { Typography, Button, Box } from "@mui/material";
import "../../css/submodal.css";

import DecryptData from "../../../../functions/DecryptData";
import GetTransactionHistory from "../../../../functions/GetTransactionHistory";

const TransHistory_MobileTable = () => {
	const [items, setItems] = useState([]);
	const [loading, setLoading] = useState(false);
	const [page, setPage] = useState(0);
	const [pageFetched, setPageFetched] = useState([]);
	const rowCount = 10;

	const containerRef = useRef(null);
	const pageWait = useRef(null);

	useEffect(() => {
		fetchItems(page); // Initial fetch on component mount
	}, []);

	useEffect(() => {
		if (page !== 0) {
			if (pageFetched.includes(page)) {
				return;
			} else {
				fetchItems(page);
			}
		}
	}, [page]);

	useEffect(() => {
		console.log("Page Fetched: ", pageFetched);
	}, [pageFetched]);

	const formatDate = (dateString) => {
		const date = new Date(dateString);
		const options = {
			month: "2-digit",
			day: "2-digit",
			year: "numeric",
			hour: "2-digit",
			minute: "2-digit",
			hour12: true,
		};
		return date.toLocaleDateString("en-US", options);
	};

	const handleScroll = () => {
		const container = containerRef.current;
		if (!container) return;

		const scrollHeight = container.scrollHeight;
		const scrollTop = container.scrollTop;
		const clientHeight = container.clientHeight;

		if (scrollHeight - scrollTop <= clientHeight + 1 && !loading) {
			// You can adjust the threshold (1 in this case) as needed
			if (pageWait.current) {
				clearTimeout(pageWait.current);
				pageWait.current = null;
			}

			pageWait.current = setTimeout(() => {
				const nextPage = page + rowCount;
				if (pageFetched.includes(nextPage)) {
					return;
				} else {
					setPage(nextPage);
				}
				clearTimeout(pageWait.current);
				pageWait.current = null;
			}, 100);
		}
	};

	const fetchItems = (currentPage) => {
		setLoading(true);
		let savedToken = DecryptData("access_token");

		if (savedToken !== null) {
			console.log("Current page:", currentPage);
			const reqHistory = GetTransactionHistory(savedToken, rowCount, currentPage);
			reqHistory.then((res) => {
				setItems((prevItems) => [...prevItems, ...res.data]);
				if (res.data.length < 1) {
					// setPage(page - rowCount);
					setPageFetched((prevItems) => prevItems.filter((item) => item !== currentPage));
				} else {
					setPageFetched((prevItems) => [...prevItems, currentPage]);
				}
			});
		}

		setLoading(false);
	};

	return (
		<div
			className='history-mobile-table'
			ref={containerRef}
			onScroll={handleScroll}
			onTouchMove={handleScroll}>
			{items.length > 0 ? (
				<>
					{items.map((transaction, index) => (
						<div
							className='transaction-item'
							key={index}>
							<div className='item-row'>
								<Typography variant='subtitle2'>Transaction Type:</Typography>
								<Typography variant='subtitle2'>{transaction.type}</Typography>{" "}
								{/* Replace with your actual transaction properties */}
							</div>
							{transaction.type === "TOPUP" ? (
								<div className='item-row'>
									<Typography variant='subtitle2'>Date:</Typography>
									<Typography variant='subtitle2'>{formatDate(transaction.date_created)}</Typography>
								</div>
							) : (
								<>
									<div className='item-row'>
										<Typography variant='subtitle2'>Charge In Date/Time:</Typography>
										<Typography variant='subtitle2'>{formatDate(transaction.charge_in_datetime)}</Typography>
									</div>
									<div className='item-row'>
										<Typography variant='subtitle2'>Plug Out Date/Time:</Typography>
										<Typography variant='subtitle2'>{formatDate(transaction.plug_out_datetime)}</Typography>
									</div>
								</>
							)}

							<div className='item-row'>
								<Typography variant='subtitle2'>Previous Balance:</Typography>
								<Typography variant='subtitle2'>
									PHP {transaction.initial_rfid_balance !== null ? transaction.initial_rfid_balance : "N/A"}
								</Typography>
							</div>
							<div className='item-row'>
								<Typography variant='subtitle2'>Resulting Balance:</Typography>
								<Typography variant='subtitle2'>
									PHP {transaction.current_rfid_balance !== null ? transaction.current_rfid_balance : "N/A"}
								</Typography>
							</div>
							<div className='item-row'>
								<Typography variant='subtitle2'>Amount:</Typography>
								<Typography
									variant='subtitle2'
									style={{
										color:
											Number(transaction.current_rfid_balance) > Number(transaction.initial_rfid_balance)
												? "green"
												: "red",
									}}>
									{Number(transaction.current_rfid_balance) > Number(transaction.initial_rfid_balance) ? "+" : "-"} PHP{" "}
									{transaction.price}
								</Typography>
							</div>
						</div>
					))}
					{loading && <p>Loading...</p>}
				</>
			) : (
				<Box>
					<Typography>No Transaction Data</Typography>
				</Box>
			)}
		</div>
	);
};

export default TransHistory_MobileTable;
