const TryLogout = async (accessToken, setLoading) => {
    const myHeaders = new Headers();
    myHeaders.append("Authorization", "Bearer " + accessToken);
    myHeaders.append("Content-Type", "application/json");

    const requestOptions = {
        method: 'POST',
        headers: myHeaders,
        redirect: 'follow'
    };

    let response;

    try {
        response = await fetch(
            `${process.env.REACT_APP_API_ADDRESS}/login/api/auth/v1/logout`,
            requestOptions
        );

        if (!response.ok) {
            // Handle specific error status codes
            if (response.status === 401) {
                console.log("Session expired. Logging out...");
                // Clear session and localStorage if token is expired
                localStorage.clear();
                setLoading(false, "Session expired. Please log in again.");
            } else {
                console.log(`Server error: ${response.status}`);
                setLoading(false, "An error occurred during logout.");
            }
        } else {
            const result = await response.json();
            // Successful logout, clear localStorage and handle the state accordingly
            localStorage.clear();
            setLoading(false, "Successfully logged out.");
            return result;
        }
    } catch (error) {
        console.log("Network error or other unexpected issue:", error);
        setLoading(false, "Network error. Please try again.");
    }
};

export default TryLogout;
