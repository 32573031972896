const GetFilterOptions = async () => {
    const myHeaders = new Headers();
    myHeaders.append("Authorization", "Basic " + process.env.REACT_APP_API_KEY);
    myHeaders.append("Accept", "application/json");
    myHeaders.append("Content-Type", "application/json");

    const graphql = JSON.stringify({
        query: "query Power_types {\r\n    power_types {\r\n        id\r\n        code\r\n        description\r\n    }\r\n    connector_types {\r\n        id\r\n        code\r\n        description\r\n    }\r\n    facilities {\r\n        id\r\n        code\r\n        description\r\n    }\r\n    amenities {\r\n        id\r\n        code\r\n        description\r\n    }\r\n    capabilities {\r\n        id\r\n        code\r\n        description\r\n    }\r\n    payment_types {\r\n        id\r\n        code\r\n        description\r\n    }\r\n    parking_types {\r\n        id\r\n        code\r\n        description\r\n    }\r\n}\r\n",
        variables: {}
    })
    const requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: graphql,
        redirect: "follow"
    };

    let response;

    try {
        response = await fetch(
            `${process.env.REACT_APP_API_ADDRESS}/booking_merchants/graphql`,
            requestOptions
        );

        return response.json()
    } catch (error) {
        console.log("Network error or other unexpected issue:", error);
    }
}

export default GetFilterOptions;