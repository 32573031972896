import RefreshToken from './RefreshToken';
import DecryptData from './DecryptData';

const CheckPlugoutDateTime = async (accessToken) => {
    let myHeaders = new Headers();
    myHeaders.append("Authorization", "Bearer " + accessToken);

    const requestOptions = {
        method: "GET",
        headers: myHeaders,
        redirect: "follow"
    };

    let response;

    try {
        // Initial request
        response = await fetch(
            `${process.env.REACT_APP_API_ADDRESS}/booking_reservation/api/v1/check_plugout_datetime`,
            requestOptions
        );

        // If response is not ok (i.e., status is not in the range 200-299)
        if (!response.ok) {
            let result = await response.json();
            if (result.status === 401 || result.status === 403) {
                console.log("Access token expired or invalid, attempting to refresh...");

                // Decrypt and retrieve refresh token from storage
                const saved_refreshToken = DecryptData('refresh_token');

                if (saved_refreshToken) {
                    // Try to refresh the token
                    const refreshResponse = await RefreshToken(saved_refreshToken);

                    if (refreshResponse.status === 200) {
                        // Successfully refreshed token
                        const newAccessToken = refreshResponse.data.access_token;
                        console.log("New access token: ", newAccessToken);

                        // Update headers with the new token
                        myHeaders = new Headers();
                        myHeaders.append("Authorization", "Bearer " + newAccessToken);
                        requestOptions.headers = myHeaders;

                        // Retry the request with the new access token
                        response = await fetch(
                            `${process.env.REACT_APP_API_ADDRESS}/booking_reservation/api/v1/check_plugout_datetime`,
                            requestOptions
                        );

                        if (!response.ok) {
                            console.log(`Retry failed with status: ${response.status}`);
                            return null;  // Retry failed
                        }

                        return await response.json();  // Return the retry result if successful
                    } else {
                        console.log("Failed to refresh token. Logging out...");
                        localStorage.clear();
                        return null;
                    }
                } else {
                    console.log("No refresh token available. Logging out...");
                    localStorage.clear();
                    return null;
                }
            } else {
                console.log(`Server error: ${response.status}`);
                return null;
            }
        }

        // Return the response if the request was successful
        return await response.json();

    } catch (error) {
        console.log("Network error or other unexpected issue:", error);
        return null;
    }
};

export default CheckPlugoutDateTime;
