const ResetPassword = async (userID, password) => {
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("Authorization", "Basic " + process.env.REACT_APP_API_KEY);

    var raw = JSON.stringify({
        "password": password
    });

    var requestOptions = {
        method: 'POST',
        headers: myHeaders,
        body: raw,
        redirect: 'follow'
    };

    let response;

    try {
        response = await fetch(
            `${process.env.REACT_APP_API_ADDRESS}/login/api/auth/v1/change-password/${userID}`,
            requestOptions
        );

        if (!response.ok) {
            // Handle specific error status codes
            if (response.status === 401) {
                console.log("Unauthorized: Token invalid.");
            } else {
                console.log(`Server error: ${response.status}`);
            }
        } else {
            return response.json()
        }
    } catch (error) {
        console.log("Network error or other unexpected issue:", error);
    }
}

export default ResetPassword;