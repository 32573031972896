import { useEffect, useState, useReducer, useContext } from "react";
import "../css/mapBody.css";
import { GoogleMap, useLoadScript, Marker, MarkerClusterer } from "@react-google-maps/api";
import MyLocationIcon from "@mui/icons-material/MyLocation";
import EVIcon from "../../../assets/EV_Sample.svg";
import MerchantIcon from "../../../assets/Merchant_Sample.svg";
import ClustererIcon from "../../../assets/MarkerClusterer.png";
import GetNearbyMerchants from "../../../functions/GetNearbyMerchants";
import DecryptData from "../../../functions/DecryptData";

import SelectMerchant from "../../../functions/SelectMerchant";
import GetCurrentLocation from "../../../functions/GetCurrentLocation";

import { Box, Chip, Typography } from "@mui/material";

import { renderTheme } from "../../../App";

const Map = (props) => {
	const philippinesBounds = {
		north: 21.1619, // Northernmost latitude of the Philippines
		south: 4.643, // Southernmost latitude of the Philippines
		east: 126.6044, // Easternmost longitude of the Philippines
		west: 116.7642, // Westernmost longitude of the Philippines
	};

	const mapOptions = {
		fullscreenControl: false,
		disableDefaultUI: true,
		gestureHandling: "greedy",
		clickableIcons: false,
		restriction: {
			latLngBounds: philippinesBounds,
			strictBounds: true, // Ensures users cannot pan outside the bounds
		},
	};

	let map = (
		<GoogleMap
			zoom={16}
			center={props.onCenterPosition}
			mapContainerClassName='map-container'
			options={mapOptions}>
			{props.onUserMarkerVisibility !== undefined && (
				<Marker
					position={props.onUserPosition}
					label={{ text: "You", color: "white", className: "marker-user-label" }}
					onClick={(event) => props.onToggleMarker(event, props.onUserPosition)}
					icon={EVIcon}
					title='This is you.'
				/>
			)}
			<MarkerClusterer
				averageCenter
				enableRetinaIcons
				styles={[
					{
						height: 75, // Icon height
						width: 60, // Icon width
						url: ClustererIcon, // URL of your cluster icon that includes the white circle
						textColor: "black", // Color of the text (number)
						textSize: 16, // Font size of the text inside the white circle
						textAlign: "center", // Ensure the text is centered horizontally
						anchorText: [-22.5, 16.5], // Adjust the anchor to move text (X, Y) inside the white circle
						fontFamily: "Arial, sans-serif", // Font family
						fontWeight: "bold", // Make the text bold
					},
				]}>
				{(clusterer) =>
					props.MerchantList?.map((data) => {
						return (
							<Marker
								onClick={(event) =>
									props.onToggleMarker(event, { lat: Number(data.address_lat), lng: Number(data.address_lng) }, data.id)
								}
								clusterer={clusterer}
								position={{ lat: Number(data.address_lat), lng: Number(data.address_lng) }}
								icon={MerchantIcon} // Your custom merchant icon
								title={data.name}
								label={{ text: data.name, color: "white", className: "marker-location-label" }}
							/>
						);
					})
				}
			</MarkerClusterer>
		</GoogleMap>
	);

	return map;
};

const MapBody = (props) => {
	const { isLoaded } = useLoadScript({ googleMapsApiKey: "AIzaSyB03iyW7pYkkVLBIAj_n0oVbJDGTEi38sM" });
	const [AccessToken, setAccessToken] = useState();
	const [UserPosition, setUserPosition] = useState();
	const [MapCenter, setMapCenter] = useState();
	const [MerchantList, setMerchantList] = useState();
	const [IsLoaded, setIsLoaded] = useState(false);
	const [UserMarkerVisibility, setUserMarkerVisibility] = useState(false);

	const { toggleActiveFilters, ActiveFilters, RenderType, StatusChange } = useContext(renderTheme);

	useEffect(() => {
		if (MapCenter === undefined) {
			setMapCenter(RenderType === "application" ? props.onUserAppLocation.location : props.onUserPosition);
		}

		setUserPosition(RenderType === "application" ? props.onUserAppLocation.location : props.onUserPosition);
		setUserMarkerVisibility(
			RenderType === "application" ? (props.onUserAppLocation.accuracy < 100 ? true : false) : props.onUserMarker
		);
	}, [props.onUserMarker, props.onUserPosition, props.onUserAppLocation]);

	useEffect(() => {
		if (props.onFilteredData !== undefined) {
			setMerchantList(props.onFilteredData);
		} else {
			if (UserPosition !== undefined) {
				if (props.onIfLoggedIn) {
					console.log("[Is_Logged_In]", props.onIfLoggedIn);
					let savedToken = DecryptData("access_token");

					if (savedToken !== null) {
						setAccessToken(savedToken);
						console.log("[ACCESS TOKEN] ====================> ", savedToken);
						setIsLoaded(false);
						if (props.onWhatModal === "modal_loading") {
							props.onModalVisibility(false, "");
						}
					}
				} else {
					let request = GetNearbyMerchants(
						"Basic " + process.env.REACT_APP_API_KEY,
						UserPosition.lng,
						UserPosition.lat,
						"guest_user"
					);
					if (!IsLoaded) {
						props.onModalVisibility(true, "modal_loading");
					}

					request.then((res) => {
						setMerchantList(null);
						if (res.errors === undefined) {
							setIsLoaded(true);
							setMerchantList(res.data.locations);
							props.onModalVisibility(false, "");
						} else {
							console.log("Got some error/s.");
						}
					});
				}
			}
		}
	}, [UserPosition, props.onFilteredData]);

	useEffect(() => {
		if (AccessToken !== undefined) {
			console.log(props.onWhatActiveCategory);
			if (props.onWhatActiveCategory !== undefined || props.onCategoryMerchantList !== "") {
				console.log(props.onCategoryMerchantList);
				setMerchantList(props.onCategoryMerchantList);
			} else {
				let request = GetNearbyMerchants(
					"Bearer " + AccessToken,
					props.onUserPosition.lng,
					props.onUserPosition.lat,
					"registered_user"
				);

				if (!IsLoaded) {
					props.onModalVisibility(true, "modal_loading");
				}

				request.then((res) => {
					setMerchantList(null);
					if (res.errors === undefined) {
						setIsLoaded(true);
						setMerchantList(res.data.location_with_favorites);
						props.onModalVisibility(false, "");
					} else {
						console.log("Got some error/s.");
					}
				});
			}
		}
	}, [AccessToken, props.onCategoryMerchantList]);

	useEffect(() => {
		console.log(MerchantList);
	}, [MerchantList]);

	const toggleFilterSelection = () => {
		props.onModalVisibility(true, "modal_choosefilter");
	};

	function toggleMarker(e, position, location_id = undefined) {
		console.log(position, location_id);
		if (UserPosition === position) {
			let posLat = position.lat + 0.0001;
			let posLng = position.lng + 0.0001;
			setMapCenter({ lat: posLat, lng: posLng });
		} else {
			setMapCenter(position);
		}
		if (location_id !== undefined) {
			let reqLocation = GetCurrentLocation();
			reqLocation.then((data_reqLocation) => {
				if (props.onIfLoggedIn) {
					let savedToken = DecryptData("access_token");
					if (savedToken !== null) {
						let request = SelectMerchant(
							savedToken,
							location_id,
							data_reqLocation.latitude,
							data_reqLocation.longitude
						);
						request.then((data1) => {
							props.onMerchantSelection({
								name: data1.data.location_with_favorite.name,
								address: data1.data.location_with_favorite.address,
								distanceAway: data1.data.location_with_favorite.distance.toFixed(2) + "km away",
								stations: data1.data.location_with_favorite.evses,
								facilities: data1.data.location_with_favorite.facilities,
								parking_types: data1.data.location_with_favorite.parking_types,
								parking_restrictions: data1.data.location_with_favorite.parking_restrictions,
								merchantID: data1.data.location_with_favorite.id,
								favorite: data1.data.location_with_favorite.favorite,
								address_lat: data1.data.location_with_favorite.address_lat,
								address_lng: data1.data.location_with_favorite.address_lng,
							});
							props.onModalVisibility(true, "modal_loading");
							setTimeout(() => {
								props.onModalVisibility(true, "modal_merchant");
							}, 1000);
						});
					}
				} else {
					props.onModalVisibility(true, "modal_login");
				}
			});
		}
	}

	useEffect(() => {
		if (props.onMerchantMarker !== 0) {
			toggleMarker(undefined, props.onMerchantMarker);
		}
	}, [props.onMerchantMarker]);

	if (!isLoaded) return <div style={{ backgroundColor: "#E5E3DF", height: "100%" }}>Loading...</div>;

	return (
		<div
			id='home_mapbody'
			style={{
				height:
					RenderType === "application"
						? StatusChange !== undefined
							? "77.5vh"
							: "92.5vh"
						: StatusChange !== undefined
						? "75vh"
						: "90vh",
			}}>
			<button
				className='btn btn-user-location'
				title='Your Location'
				data-marker={"user_marker"}
				onClick={(event) => {
					toggleMarker(event, UserPosition);
				}}>
				<MyLocationIcon style={{ pointerEvents: "none" }} />
			</button>
			{ActiveFilters !== undefined && (
				<Box
					onClick={toggleFilterSelection}
					sx={{
						position: "absolute",
						left: 0,
						zIndex: 10,
						padding: "0.5% 1% .5% 1%",
						backgroundColor: "#1a6fff",
						width: "100%",
						display: "flex",
						alignItems: "center",
						gap: { xs: 0, lg: 2 },
						padding: { xs: "8px" },
					}}>
					<Typography sx={{ color: "white" }}>Charger Locations Filtered by:</Typography>
					{/* POWER TYPES */}
					{ActiveFilters.power_types.length > 0 && (
						<Chip
							sx={{ width: "max-content", color: "white" }}
							label={
								<>
									<Typography
										variant='caption'
										sx={{ fontWeight: "bold" }}>
										Power Types:&nbsp;
									</Typography>
									<Typography variant='caption'>
										{ActiveFilters.power_types.map((type) => type.replace(/_/g, " ")).join(", ")}
									</Typography>
								</>
							}
							variant='outlined'
						/>
					)}
					{/* CONNECTOR TYPES */}
					{ActiveFilters.connector_types.length > 0 && (
						<Chip
							sx={{ width: "max-content", color: "white" }}
							label={
								<>
									<Typography
										variant='caption'
										sx={{ fontWeight: "bold" }}>
										Connector Types:&nbsp;
									</Typography>
									<Typography variant='caption'>
										{ActiveFilters.connector_types.map((type) => type.replace(/_/g, " ")).join(", ")}
									</Typography>
								</>
							}
							variant='outlined'
						/>
					)}
					{/* FACILITIES */}
					{ActiveFilters.facilities.length > 0 && (
						<Chip
							sx={{ width: "max-content" }}
							label={
								<>
									<Typography
										variant='caption'
										sx={{ fontWeight: "bold" }}>
										Facilities:&nbsp;
									</Typography>
									<Typography variant='caption'>
										{ActiveFilters.facilities.map((type) => type.replace(/_/g, " ")).join(", ")}
									</Typography>
								</>
							}
							variant='outlined'
						/>
					)}
				</Box>
			)}

			<Map
				onUserPosition={UserPosition}
				onCenterPosition={MapCenter}
				onToggleMarker={toggleMarker}
				onUserMarkerVisibility={UserMarkerVisibility}
				MerchantList={MerchantList}
			/>
		</div>
	);

	// 14.559999022307489, 121.02645128031159
};

export default MapBody;
