import DecryptData from "./DecryptData"; // To decrypt accessToken if it's stored
import RefreshToken from "./RefreshToken"; // To refresh token

const TryReserve = async (AccessToken, ReservationData) => {
    const myHeaders = new Headers();
    myHeaders.append("Accept", "application/json");
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("Authorization", "Bearer " + AccessToken);

    const raw = JSON.stringify({
        "evc_id": ReservationData.evc_id,
        "connector_id": ReservationData.connector_id,
        "timeslot_id": ReservationData.timeslot_id,
        "timeslot_date": ReservationData.timeslot_date,
        "next_timeslot_id": ReservationData.next_timeslot_id,
        "next_timeslot_date": ReservationData.next_timeslot_date,
        "date": ReservationData.date,
        "current_time": ReservationData.current_time,
        "want_to_book": ReservationData.want_to_book
    });

    const requestOptions = {
        method: 'POST',
        headers: myHeaders,
        body: raw,
        redirect: 'follow'
    };

    let response;

    try {
        // Initial request to reserve the slot
        response = await fetch(
            `${process.env.REACT_APP_API_ADDRESS}/booking_reservation/api/v1/slots/reserve`,
            requestOptions
        );

        if (!response.ok) {
            // If the response status is 401 (Unauthorized), handle token refresh
            if (response.status === 401) {
                console.log("Session expired. Attempting token refresh...");

                const savedRefreshToken = DecryptData("refresh_token"); // Retrieve the refresh token

                if (savedRefreshToken) {
                    // Try to refresh the access token
                    const newAccessToken = await RefreshToken(savedRefreshToken);

                    if (newAccessToken) {
                        // If token refresh is successful, retry the original request
                        console.log("Retrying request with new access token...");
                        return TryReserve(newAccessToken, ReservationData); // Recursive retry
                    } else {
                        console.log("Failed to refresh token. Logging out...");
                        // If token refresh fails, clear session and redirect to login
                        localStorage.clear();
                        window.location.href = "/login"; // Redirect to login page
                    }
                } else {
                    console.log("No refresh token available. Logging out...");
                    // If no refresh token is found, log out
                    localStorage.clear();
                    window.location.href = "/login"; // Redirect to login page
                }
            } else {
                // Handle other server errors
                console.log(`Server error: ${response.status}`);
            }
            return { error: true, status: response.status };  // Return an error object
        } else {
            // If request is successful, return the response data as JSON
            return response.json();
        }
    } catch (error) {
        console.log("Network error or other unexpected issue:", error);
        return { error: true, message: error.message };  // Handle unexpected issues
    }
};

export default TryReserve;
