import EncryptData from "./EncryptData";

const RefreshToken = async (refreshToken) => {
    const myHeaders = new Headers();
    myHeaders.append("Authorization", "Bearer " + refreshToken);

    const requestOptions = {
        method: 'GET',
        headers: myHeaders,
        redirect: 'follow'
    };

    let response;

    try {
        response = await fetch(
            `${process.env.REACT_APP_API_ADDRESS}/login/api/auth/v1/refresh`,
            requestOptions
        );

        if (!response.ok) {
            localStorage.clear();
            // Handle specific error status codes
            if (response.status === 403) {
                console.log("Unauthorized: Token invalid.");
                return null;
            } else {
                console.log(`Server error: ${response.status}`);
                return null;
            }
        } else {
            const res = await response.json();

            // Post token information to React Native WebView, if available
            if (window.ReactNativeWebView) {
                window.ReactNativeWebView.postMessage(JSON.stringify({
                    type: "TOKEN_REFRESH",
                    refresh_token: res.data.refresh_token,
                    access_token: res.data.access_token,
                }));
            }

            // Encrypt and store the new tokens
            EncryptData("access_token", res.data.access_token);
            EncryptData("refresh_token", res.data.refresh_token);

            // Return the new token data
            return res;
        }
    } catch (error) {
        console.log("Network error or other unexpected issue:", error);
        return null;
    }
};

export default RefreshToken;
