import DecryptData from "./DecryptData";
import RefreshToken from "./RefreshToken";  // Assuming this function exists to refresh the token

const TryFilterLocation = async (type, Latitude, Longitude, filterItems) => {

    const filterType = type === "default" ? "filter_locations" : "filter_locations_with_favorites";

    const ChooseFilter = async (authHeader) => {
        const myHeaders = new Headers();
        myHeaders.append("Authorization", authHeader);
        myHeaders.append("Content-Type", "application/json");
        myHeaders.append("Accept", "application/json");

        const graphql = JSON.stringify({
            query: `query Filter_locations {\r\n    ${filterType}(\r\n        lat: ${Latitude}\r\n        lng: ${Longitude}\r\n        facilities: ${filterItems.facilities !== undefined ? filterItems.facilities : null}\r\n        capabilities: ${filterItems.capabilities !== undefined ? filterItems.capabilities : null}\r\n        payment_types: ${filterItems.payment_types !== undefined ? filterItems.payment_types : null}\r\n        parking_types: ${filterItems.parking_types !== undefined ? filterItems.parking_types : null}\r\n        connector_types: ${filterItems.connector_types !== undefined ? filterItems.connector_types : null}\r\n        power_types: ${filterItems.power_types !== undefined ? filterItems.power_types : null}\r\n    ) {\r\n        id\r\n        publish\r\n        name\r\n        address\r\n        address_lat\r\n        address_lng\r\n        city\r\n        distance\r\n        evses {\r\n            uid\r\n            evse_id\r\n            serial_number\r\n            meter_type\r\n            status\r\n            cpo_location_id\r\n            current_ws_connection_id\r\n            server_id\r\n            date_created\r\n connectors {\r\n                id\r\n                evse_uid\r\n                connector_id\r\n                standard\r\n                format\r\n                max_voltage\r\n                max_amperage\r\n                max_electric_power\r\n                status\r\n            }\r\n        }\r\n        facilities {\r\n            id\r\n            code\r\n            description\r\n        }\r\n    }\r\n}\r\n`,
            variables: {}
        });

        const requestOptions = {
            method: "POST",
            headers: myHeaders,
            body: graphql,
            redirect: "follow"
        };

        let response;

        try {
            response = await fetch(
                `${process.env.REACT_APP_API_ADDRESS}/booking_merchants/graphql`,
                requestOptions
            );

            const responseData = await response.json();

            // Handle 401 response (Unauthorized)
            if (!response.ok && response.status === 401) {
                if (authHeader.startsWith("Bearer")) {
                    console.log("Access token expired, attempting to refresh...");
                    // Try to refresh the token if it's a Bearer token
                    const savedRefreshToken = DecryptData("refresh_token");

                    if (savedRefreshToken) {
                        // Try to refresh the token
                        const refreshResponse = await RefreshToken(savedRefreshToken);

                        if (refreshResponse && refreshResponse.status === 200) {
                            // New token is obtained, retry the request
                            const newAccessToken = refreshResponse.data.access_token;
                            console.log("New access token:", newAccessToken);

                            // Retry with new Bearer token
                            myHeaders.set("Authorization", `Bearer ${newAccessToken}`);
                            requestOptions.headers = myHeaders;

                            response = await fetch(
                                `${process.env.REACT_APP_API_ADDRESS}/booking_merchants/graphql`,
                                requestOptions
                            );

                            return response.json();
                        } else {
                            console.log("Failed to refresh token. Logging out...");
                            localStorage.clear();  // Log out if the refresh fails
                            return null;
                        }
                    } else {
                        console.log("No refresh token available. Logging out...");
                        localStorage.clear();
                        return null;
                    }
                } else {
                    // If Basic authentication, just return the error
                    return responseData;
                }
            }

            return responseData;  // Return successful response data

        } catch (error) {
            console.log("Network error or other unexpected issue:", error);
        }
    };

    if (type === "default") {
        return ChooseFilter(`Basic ${process.env.REACT_APP_API_KEY}`);
    } else {
        let savedToken = DecryptData("access_token");

        if (savedToken !== null) {
            return ChooseFilter(`Bearer ${savedToken}`);
        } else {
            console.log("No access token available. Logging out...");
            localStorage.clear();  // Clear if no token is found
            return null;
        }
    }
};

export default TryFilterLocation;
