import RefreshToken from './RefreshToken';
import DecryptData from './DecryptData';

const TryCancelReservation = async (accessToken, reservationID, evcID, timeslotID, connectorID) => {
    const myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("Authorization", "Bearer " + accessToken);

    const raw = JSON.stringify({
        "reservation_id": reservationID,
        "evc_id": evcID,
        "timeslot_id": timeslotID,
        "connector_id": connectorID,
    });

    const requestOptions = {
        method: 'PATCH',
        headers: myHeaders,
        body: raw,
        redirect: 'follow'
    };

    let response;

    try {
        // Initial request
        response = await fetch(
            `${process.env.REACT_APP_API_ADDRESS}/booking_reservation/api/v1/slots/cancel`,
            requestOptions
        );

        const responseData = await response.json();

        // Log initial response status and data
        console.log("Initial response status:", response.status);
        console.log("Initial response data:", responseData);

        // Check if the response is successful and contains errors
        if (response.ok) {
            return responseData;
        } else if (response.status === 401) {
            console.log("Access token expired, attempting to refresh...");

            // Decrypt and retrieve refresh token from localStorage
            const saved_refreshToken = DecryptData('refresh_token');

            if (saved_refreshToken) {
                // Try to refresh the token
                const refreshResponse = await RefreshToken(saved_refreshToken);

                if (refreshResponse.status === 200) {
                    // Successfully refreshed the token, update the access token and retry the request
                    const newAccessToken = refreshResponse.data.access_token;
                    console.log("New access token: ", newAccessToken);

                    // Update headers with the new token
                    myHeaders.set("Authorization", "Bearer " + newAccessToken);
                    requestOptions.headers = myHeaders;

                    // Retry the request with the new token
                    response = await fetch(
                        `${process.env.REACT_APP_API_ADDRESS}/booking_reservation/api/v1/slots/cancel`,
                        requestOptions
                    );

                    const retryData = await response.json();
                    console.log("Retry response data:", retryData);

                    if (!response.ok) {
                        console.log(`Retry failed with status: ${response.status}`);
                        return null;
                    }
                    return retryData;  // Return the retry data if successful
                } else {
                    console.log("Failed to refresh token. Logging out...");
                    localStorage.clear();
                    return null;
                }
            } else {
                console.log("No refresh token available. Logging out...");
                localStorage.clear();
                return null;
            }
        } else if (response.status === 404) {
            console.log("Reservation not found (404).");
            // Handle 404 (resource not found) specifically if needed
        } else {
            console.log(`Server error: ${response.status}`);
        }
    } catch (error) {
        console.log("Network error or other unexpected issue:", error);
        throw error;  // Rethrow the error or handle it accordingly
    }
};

export default TryCancelReservation;
