import { useState, useContext, useEffect } from "react";
import { motion } from "framer-motion";
import "../css/submodal.css";

import CloseIcon from "@mui/icons-material/Close";
import { renderTheme } from "../../../App";
import { Button } from "@mui/material";

import ActivateCharger from "../../../functions/ActivateCharger";
import GetCurrentLocation from "../../../functions/GetCurrentLocation";
import DecryptData from "../../../functions/DecryptData";

const variants = {
	open: { scale: 1 },
	closed: { scale: 0 },
};

const SubModalChargeNow = (props) => {
	const [isOpen, setIsOpen] = useState(false);

	const [EVSEuID, setEVSEuID] = useState("");
	const [SelectedConnectorID, setSelectedConnectorID] = useState("");
	const [LocationID, setLocationID] = useState("");

	const [RequestToken, setRequestToken] = useState();
	const [ChargeNowStep, setChargeNowStep] = useState("step1");
	// const [SelectedConnector, setSelectedConnector] = useState();
	const [SelectedIndex, setSelectedIndex] = useState();
	const { toggleStatusChange, setChargeStep } = useContext(renderTheme);

	// CONNECTOR COMPONENT
	const ConnectorItem = (props) => {
		const [ChargeState, setChargeState] = useState();
		const [ConnectorDetails, setConnectorDetails] = useState();
		const [Index, setIndex] = useState();

		useEffect(() => {
			if (props.itemDetails !== undefined) {
				setConnectorDetails(props.itemDetails.data);
				setIndex(props.itemDetails.index + 1);
			}
		}, [props.itemDetails]);

		useEffect(() => {
			if (ConnectorDetails !== undefined) {
				let currentDate = GetCurrentDate();
				let currentTime = getCurrentTimeWithSeconds();
				let reqLocation = GetCurrentLocation();
				reqLocation.then(async (data_reqLocation) => {
					let savedToken = DecryptData("access_token");
					if (savedToken !== null) {
						let request = ActivateCharger(
							savedToken,
							LocationID,
							ConnectorDetails.evse_uid,
							"inquire",
							data_reqLocation.latitude,
							data_reqLocation.longitude,
							currentTime,
							currentDate,
							ConnectorDetails.connector_id
						);
						const res = await request;
						setChargeState(res.data.remarks);
					}
				});
			}
		}, [ConnectorDetails]);

		useEffect(() => {
			console.log(ChargeState);
		}, [ChargeState]);

		return (
			<div className='connector-holder'>
				<span>
					{Index}. {ConnectorDetails !== undefined && ConnectorDetails.standard.replace("_", " ")}
				</span>
				<Button
					disabled={ChargeState !== "CHARGER_AVAILABLE"}
					variant='contained'
					size='small'
					onClick={() => toggleSelectedConnector(ConnectorDetails, Index)}>
					{ChargeState === "TIMESLOT_RESERVED"
						? "RESERVED"
						: ChargeState === "TIMESLOT_OFFLINE" || ChargeState === "CHARGER_OFFLINE"
						? "OFFLINE"
						: ChargeState === "OUTSIDE_OF_LOCATION_AREA"
						? "NOT NEARBY"
						: ChargeState === "TIMESLOT_CHARGING"
						? "IN USE"
						: ChargeState === "INSUFFICIENT_BALANCE"
						? "INSUFFICIENT BALANCE"
						: "SELECT"}
				</Button>
			</div>
		);
	};

	const toggleSelectedConnector = (data, index) => {
		toggleChargeNowStep("step2");
		// setSelectedConnector(JSON.stringify(data));
		setSelectedIndex(index);
	};

	const toggleChargeNowStep = (step) => {
		setChargeNowStep(step);
	};

	const toggleSubModalClose = () => {
		setIsOpen(false);
		setTimeout(() => {
			props.onToggleSubModal(false, undefined);
		}, 250);
	};

	const getCurrentTimeWithSeconds = () => {
		const now = new Date();
		const hours = now.getHours().toString().padStart(2, "0");
		const minutes = now.getMinutes().toString().padStart(2, "0");
		const seconds = now.getSeconds().toString().padStart(2, "0");

		return `${hours}:${minutes}:${seconds}`;
	};

	const GetCurrentDate = () => {
		const dateObject = new Date();

		// Get date components
		const year = dateObject.getFullYear();
		const month = (dateObject.getMonth() + 1).toString().padStart(2, "0");
		const day = dateObject.getDate().toString().padStart(2, "0");

		// Formatted date
		const formattedDate = `${year}-${month}-${day}`;

		return formattedDate;
	};

	const toggleActivateCharger = () => {
		let savedToken = DecryptData("access_token");

		if (savedToken !== null) {
			let currentDate = GetCurrentDate();
			let currentTime = getCurrentTimeWithSeconds();

			let reqLocation = GetCurrentLocation();

			reqLocation.then((data_reqLocation) => {
				let request = ActivateCharger(
					savedToken,
					LocationID,
					EVSEuID,
					"activate",
					data_reqLocation.latitude,
					data_reqLocation.longitude,
					currentTime,
					currentDate,
					SelectedConnectorID
				);
				request.then((res) => {
					console.log("ACTIVATING >>>>>>", res.data.remarks);
					if (res.data.remarks === "SUCCESS") {
						setChargeStep("WAITING");
						// setIsActivated(true);
						toggleStatusChange("USER_CHARGING");
						props.onSubModalClose(false, undefined);
						props.onModalVisibility(true, "modal_charging_reservation_details");
						props.onAlertVisibility(true, "Charger activated! Connect the plug into your EV now.", "info");
					}
				});
			});
		}
	};

	useEffect(() => {
		setIsOpen(true);
	}, []);

	useEffect(() => {
		if (props.onWhatSlotData !== undefined) {
			if (props.onWhatSlotData.ConnectorID !== undefined) {
				setSelectedConnectorID(props.onWhatSlotData.ConnectorID);
			}

			if (props.onWhatSlotData.LocationID !== undefined) {
				setLocationID(props.onWhatSlotData.LocationID);
			}

			if (props.onWhatSlotData.EVSEuID !== undefined) {
				setEVSEuID(props.onWhatSlotData.EVSEuID);
			}
		}
	}, [props.onWhatSlotData]);

	return (
		<div id='submodal_resconfirm'>
			<motion.div
				className='submodal-card card-chargenow'
				initial={{ scale: 0, translateY: "-15%" }}
				animate={isOpen ? "open" : "closed"}
				variants={variants}
				transition={{ duration: 0.3 }}>
				<div className={"submodal-header default"}>
					<span className='header-blank'>&nbsp;</span>
					<span>Charge Now</span>
					<span className='header-close'>
						<CloseIcon
							className='submodal-close-btn'
							onClick={toggleSubModalClose}
						/>
					</span>
				</div>
				<div className='submodal-content content-chargenow'>
					<div className='chargenow-step2'>
						<span className='text-center'>Are you sure you want to activate this charger with the selected plug?</span>
						<span className='d-flex justify-content-between mt-5'>
							<Button
								color='error'
								onClick={() => toggleChargeNowStep("step1")}>
								No
							</Button>
							<Button
								variant='contained'
								onClick={toggleActivateCharger}>
								Start Charger
							</Button>
						</span>
					</div>
				</div>
			</motion.div>
		</div>
	);
};

export default SubModalChargeNow;
