import { useEffect, useState, useContext, useRef } from "react";
import { motion } from "framer-motion";
import "../css/modal.css";
import CloseIcon from "@mui/icons-material/Close";
import {
	Button,
	Typography,
	FormControl,
	InputLabel,
	OutlinedInput,
	InputAdornment,
	IconButton,
	TextField,
} from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";
import { Visibility, VisibilityOff } from "@mui/icons-material";

import TryLogin from "../../../functions/TryLogin";
import EncryptData from "../../../functions/EncryptData";

import { renderTheme } from "../../../App";

const variants = {
	open: { opacity: 1, y: "-10%" },
	closed: { opacity: 0, y: "-100%" },
};

const ModalLogin = (props) => {
	const [isOpen, setIsOpen] = useState(false);
	const [Username, setUsername] = useState("");
	const [Password, setPassword] = useState("");
	const [IsLoading, setIsLoading] = useState(false);
	const [IsPasswordVisible, setIsPasswordVisible] = useState(false);
	const inputRef = useRef(null);

	// RENDER THEME
	const { isMobile } = useContext(renderTheme);

	const toggleModalClose = () => {
		setIsOpen(false);
		setTimeout(() => {
			props.onModalClose(false, "");
		}, 250);
	};

	const handleKeyPress = (event) => {
		if (event.key === "Enter") {
			toggleLogin();
		}
	};

	const handleMouseDownPassword = (event) => event.preventDefault();

	const toggleLogin = (event, res) => {
		setIsLoading(true);
		if (Username === "" || Password === "") {
			setTimeout(() => {
				setIsLoading(false);
				props.onAlertVisibility(true, "Username and/or Password can't be left blank.", "error");
			}, 1000);
		} else {
			let response = TryLogin(Username, Password, setIsLoading);
			response.then((data) => {
				console.log(data);
				if (data.status === "Forbidden") {
					console.log(response);
					props.onAlertVisibility(true, "User not found!", "error");
					setIsLoading(false);
				} else {
					let request = data.json();
					request.then((res) => {
						if (res.message === "SUCCESS") {
							props.onAlertVisibility(
								true,
								"Welcome to ParkNcharge! Let's power up and make your day brighter. Happy charging!",
								"info"
							);
							setTimeout(() => {
								props.onIsLoggedIn(event, true);
								setTimeout(() => {
									// window.location.reload(false);
									props.onModalClose(false, "");
								}, 1000);
							});

							const accessTokenExpiration = new Date(res.data.access_expires_in * 1000);
							console.log(res.data.access_token);
							console.log(res.data.refresh_token);
							EncryptData("token_expiration", accessTokenExpiration);
							EncryptData("access_token", res.data.access_token);
							EncryptData("refresh_token", res.data.refresh_token);
						}
					});
				}
			});
		}
	};

	useEffect(() => {
		setIsOpen(true);
	}, []);

	const handleUsernameInput = (e) => {
		setUsername(e.target.value);
	};

	const handlePasswordInput = (e) => {
		setPassword(e.target.value);
	};

	const togglePasswordVisibility = () => {
		const inputElement = inputRef.current.querySelector("input");

		if (inputElement) {
			const currentCursorPos = inputElement.selectionStart;

			setIsPasswordVisible((prev) => !prev);

			setTimeout(() => {
				if (inputElement.setSelectionRange) {
					inputElement.setSelectionRange(currentCursorPos, currentCursorPos);
				}
			}, 0);
		}
	};

	return (
		<div id='modal_login'>
			<motion.div
				className='modal-card card-login'
				initial={{ opacity: 0, y: "-100%" }}
				animate={isOpen ? "open" : "closed"}
				variants={variants}
				transition={{ duration: 0.3 }}>
				<div className='modal-header'>
					<span className='header-blank'>&nbsp;</span>
					<span>Login</span>
					<span className='header-close'>
						<CloseIcon
							className='modal-close-btn'
							onClick={toggleModalClose}
						/>
					</span>
				</div>
				<div className='modal-content content-login'>
					<div className='form-input'>
						<label htmlFor='form_txt_username'>Username</label>
						<input
							type='text'
							className='form-control'
							value={Username}
							onChange={handleUsernameInput}
							id='form_txt_username'
						/>
					</div>
					<div className='form-input'>
						<label htmlFor='form_txt_password'>Password</label>
						<FormControl>
							<OutlinedInput
								ref={inputRef}
								inputProps={{
									className: "modified-text-field",
								}}
								id='form_txt_password'
								type={IsPasswordVisible ? "text" : "password"}
								onChange={handlePasswordInput}
								onKeyDown={handleKeyPress}
								value={Password}
								endAdornment={
									<InputAdornment position='end'>
										<IconButton
											aria-label='toggle password visibility'
											onClick={togglePasswordVisibility}
											onMouseDown={handleMouseDownPassword}
											edge='end'>
											{IsPasswordVisible ? <Visibility /> : <VisibilityOff />}
										</IconButton>
									</InputAdornment>
								}
							/>
						</FormControl>
						{/* <label htmlFor='form_txt_password'>Password</label>
						<input
							type='password'
							className='form-control'
							value={Password}
							onChange={handlePasswordInput}
							onKeyDown={handleKeyPress}
							inputmode='text'
							id='form_txt_password'
						/> */}
					</div>
					<div style={{ display: "flex", justifyContent: "flex-end", width: isMobile ? "90%" : "67.5%" }}>
						<Button
							size='small'
							style={{ textTransform: "none" }}
							onClick={() => props.onModalClose(true, "modal_forgotpassword")}>
							Forgot password?
						</Button>
					</div>
					<div>
						<button
							className='my-2 btn btn-primary'
							type='button'
							onClick={(event) => {
								toggleLogin(event, true);
							}}>
							{IsLoading ? (
								<CircularProgress
									color='inherit'
									size={16}
								/>
							) : (
								"Log In"
							)}
						</button>
					</div>
					<div style={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
						<Typography
							component={"caption"}
							sx={{ whiteSpace: "nowrap" }}>
							Don't have an account yet?
						</Typography>
						<Button
							style={{ textTransform: "none", color: "#ff8f00" }}
							onClick={() => props.onModalClose(true, "modal_signup")}>
							Sign Up
						</Button>
					</div>
				</div>
			</motion.div>
		</div>
	);
};

export default ModalLogin;
