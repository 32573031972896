import RefreshToken from './RefreshToken';
import DecryptData from './DecryptData';

const fetchWithToken = async (url, requestOptions, myHeaders) => {
    let response;
    try {
        response = await fetch(url, requestOptions);
        const responseData = await response.json();
        return { response, responseData };
    } catch (error) {
        console.log("Network error or other unexpected issue:", error);
        throw error;  // Rethrow to be caught in the calling function
    }
};

const refreshAndRetry = async (url, requestOptions, myHeaders, reservationID) => {
    console.log("Access token expired, attempting to refresh...");

    const saved_refreshToken = DecryptData('refresh_token');
    if (!saved_refreshToken) {
        console.log("No refresh token available. Logging out...");
        localStorage.clear();
        return null;
    }

    // Try to refresh the token
    const refreshResponse = await RefreshToken(saved_refreshToken);
    if (refreshResponse.status !== 200) {
        console.log("Failed to refresh token. Logging out...");
        localStorage.clear();
        return null;
    }

    // Successfully refreshed the token
    const newAccessToken = refreshResponse.data.access_token;
    console.log("New access token:", newAccessToken);

    // Update headers with the new token
    myHeaders.set("Authorization", "Bearer " + newAccessToken);
    requestOptions.headers = myHeaders;

    // Retry the original request with the new token
    const { response, responseData } = await fetchWithToken(
        `${process.env.REACT_APP_API_ADDRESS}/booking_reservation/api/v1/slots/reservations/activate/${reservationID}`,
        requestOptions,
        myHeaders
    );

    if (!response.ok) {
        console.log(`Retry failed with status: ${response.status}`);
        return null;
    }

    return responseData;  // Return retry response data
};

const UseReservation = async (accessToken, reservationID) => {
    const myHeaders = new Headers();
    myHeaders.append("Authorization", "Bearer " + accessToken);

    const requestOptions = {
        method: "POST",
        headers: myHeaders,
        redirect: "follow"
    };

    try {
        // Initial request
        const { response, responseData } = await fetchWithToken(
            `${process.env.REACT_APP_API_ADDRESS}/booking_reservation/api/v1/slots/reservations/activate/${reservationID}`,
            requestOptions,
            myHeaders
        );

        // Log initial response status and data
        console.log("Initial response status:", response.status);
        console.log("Initial response data:", responseData);

        // Check if the response is successful or contains a 401 error
        if (response.ok) {
            // If the request is successful, return the response data
            return responseData;
        } else if (responseData.errors && responseData.errors[0].status === 401) {
            // If token expired (401), attempt to refresh and retry
            return refreshAndRetry(
                `${process.env.REACT_APP_API_ADDRESS}/booking_reservation/api/v1/slots/reservations/activate/${reservationID}`,
                requestOptions,
                myHeaders,
                reservationID
            );
        } else {
            // Log server error if response is not okay
            console.log(`Server error: ${response.status}`);
            return null;
        }
    } catch (error) {
        console.log("Network error or other unexpected issue:", error);
        return null;  // Handle the error gracefully or rethrow
    }
};

export default UseReservation;
