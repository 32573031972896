import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { motion } from "framer-motion";
import { Html5Qrcode } from "html5-qrcode";
import "../css/modal.css";
import CloseIcon from "@mui/icons-material/Close";
import { Box, Typography, Skeleton, CircularProgress, Button } from "@mui/material";

import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import GetEVSEChargerDetails from "../../../functions/GetEVSEChargerDetails";
import GetCurrentLocation from "../../../functions/GetCurrentLocation";
import ActivateCharger from "../../../functions/ActivateCharger";
import DecryptData from "../../../functions/DecryptData";

const variants = {
	open: { scale: 1 },
	closed: { scale: 0 },
};

const ModalQRScanner = (props) => {
	const navigate = useNavigate();
	const [isOpen, setIsOpen] = useState(false);
	const [IsCameraLoading, setIsCameraLoading] = useState(false);
	const [QRResultURL, setQRResultURL] = useState("");
	const [CameraQRScanner, setCameraQRScanner] = useState("");
	const [QRChargingPhase, setQRChargingPhase] = useState("phase1");

	const toggleModalClose = () => {
		setIsOpen(false);
		if (CameraQRScanner !== "") {
			CameraQRScanner.stop();
		}
		setTimeout(() => {
			props.onModalClose(false, "");
			props.onSubModalVisibility(false, undefined);
		}, 0);
	};

	useEffect(() => {
		if (!(QRResultURL === "")) {
			console.log(QRResultURL);
		}
	});

	useEffect(() => {
		setIsOpen(true);
		if (QRChargingPhase === "phase1") {
			const qrScanner = new Html5Qrcode(/* element id */ "qrscanner_body");
			setCameraQRScanner(qrScanner);
		}
		// setTimeout(() => {
		// 	html5QrCode.stop();
		// 	toggleModalClose();
		// 	props.onAlertVisibility(true, "Reservation activated successfully!", "success");
		// }, 5000);
	}, []);

	useEffect(() => {
		if (CameraQRScanner !== "") {
			let qrboxFunction = function (viewfinderWidth, viewfinderHeight) {
				let minEdgePercentage = 0.75; // 75%
				let minEdgeSize = Math.min(viewfinderWidth, viewfinderHeight);
				let qrboxSize = Math.floor(minEdgeSize * minEdgePercentage);
				return {
					width: qrboxSize,
					height: qrboxSize,
				};
			};
			setIsCameraLoading(true);

			Html5Qrcode.getCameras()
				.then((devices) => {
					if (devices && devices.length) {
						// Try to find the rear camera first
						const rearCamera = devices.find((camera) => camera.label.toLowerCase().includes("back"));

						// Use the rear camera if found, otherwise use the first available camera (usually front)
						const selectedCamera = rearCamera || devices[0];

						if (selectedCamera) {
							setIsCameraLoading(false);
							console.log("Selected camera:", selectedCamera);

							CameraQRScanner.start(
								selectedCamera.id, // Use the selected camera's device ID
								{
									fps: 10, // Frame per second for QR code scanning
									qrbox: qrboxFunction, // QR box function for bounding box
								},
								(decodedText, decodedResult) => {
									const path = new URL(decodedText).pathname;
									console.log(path);
									setQRResultURL(path);
									// Optionally stop the scanner after scanning
									// CameraQRScanner.stop();
									// toggleModalClose();
								},
								(errorMessage) => {
									console.warn(errorMessage);
								}
							).catch((err) => {
								console.warn("Error starting QR scanner:", err);
								// Handle start failure
							});
						} else {
							console.warn("No cameras found.");
							setIsCameraLoading(false);
						}
					}
				})
				.catch((err) => {
					console.error("Error fetching cameras:", err);
					// Handle error
				});
		}
	}, [CameraQRScanner]);

	useEffect(() => {
		if (QRResultURL !== "") {
			CameraQRScanner.stop();
			setCameraQRScanner("");
			setQRChargingPhase("phase2");
		}
	}, [QRResultURL]);

	return (
		<div id='modal_qrscanner'>
			<motion.div
				className='modal-qrscanner-body'
				initial={{ scale: 0 }}
				animate={isOpen ? "open" : "closed"}
				variants={variants}
				transition={{ duration: 0.3 }}>
				<div className='modal-card card-qrscanner'>
					<div className='modal-header'>
						<span className='header-close scan-qr'>
							<ArrowBackIosNewIcon
								className='modal-close-btn'
								onClick={() => toggleModalClose()}
							/>
							&nbsp;
							<div className='description-merchant-name-rate'>
								<Typography sx={{ fontSize: "1.5rem", fontWeight: "bold" }}>QR-Code Charging</Typography>
							</div>
						</span>
						<span className='header-blank'>&nbsp;</span>
						<span className='header-blank'>&nbsp;</span>
					</div>
					{QRChargingPhase === "phase1" ? (
						<Box sx={{ width: "100%", height: "100%" }}>
							{IsCameraLoading && (
								<Box id='qrscanner_skeleton'>
									<Box
										sx={{
											display: "flex",
											justifyContent: "center",
											alignItems: "center",
											backgroundColor: "gainsboro",
											width: "100%",
											height: "100%",
										}}>
										<Box sx={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
											<Typography variant='subtitle1'>Loading Camera</Typography>
											<CircularProgress />
										</Box>
									</Box>
								</Box>
							)}
							<div id='qrscanner_body'></div>
							{!IsCameraLoading && (
								<Box
									sx={{
										width: "100%",
										height: "30vh",
										position: "fixed",
										bottom: 0,
										padding: "16px 8px",
										backgroundColor: "#0d6efd",
										overflowY: "auto",
									}}>
									<Typography
										variant='subtitle2'
										sx={{ fontWeight: "bold", fontSize: "16px", color: "rgb(255, 143, 0)" }}>
										Charging via QR-Code Instruction:
									</Typography>
									<Box sx={{ display: "flex", flexDirection: "column", paddingX: "16px", color: "white" }}>
										<Typography variant='subtitle2'>1. Scan the QR-Code of the charger to be used.</Typography>
										<Typography variant='subtitle2'>
											2. Make sure you have sufficient balance for atleast the minimum charging price (1 hour) base on
											the rate of the charger.
										</Typography>
										<Typography variant='subtitle2'>3. Select the connector that matches your vehicle type.</Typography>
										<Typography variant='subtitle2'>4. Activate, happy charging!</Typography>
									</Box>
								</Box>
							)}
						</Box>
					) : (
						<ChargingStationDetails
							QRCodeDetails={QRResultURL}
							setSubmodal={props.onSubModalVisibility}
						/>
					)}
				</div>
			</motion.div>
		</div>
	);
};

const ChargingStationDetails = ({ QRCodeDetails, setSubmodal }) => {
	const [ChargerStationDetails, setChargerStationDetails] = useState("");
	const [IsLoading, setIsLoading] = useState(false);
	const [RequestToken, setRequestToken] = useState("");

	useEffect(() => {
		const pathParts = QRCodeDetails.split("/");
		const qrCode = pathParts[1]; // QR-0004
		const uniqueId = pathParts[2];
		console.log(pathParts);
		setIsLoading(true);

		let request = GetEVSEChargerDetails(qrCode, uniqueId);
		request.then((res) => {
			if (res.status === 200) {
				setChargerStationDetails(res.data);
			}
			setIsLoading(false);
		});
	}, []);

	useEffect(() => {
		if (ChargerStationDetails !== "") {
			console.log(ChargerStationDetails);
			let currentDate = GetCurrentDate();
			let currentTime = getCurrentTimeWithSeconds();
			let reqLocation = GetCurrentLocation();
			reqLocation.then(async (data_reqLocation) => {
				let savedToken = DecryptData("access_token");

				if (savedToken !== null) {
					let request = ActivateCharger(
						savedToken,
						ChargerStationDetails.location_id,
						ChargerStationDetails.evse_uid,
						"inquire",
						data_reqLocation.latitude,
						data_reqLocation.longitude,
						currentTime,
						currentDate,
						ChargerStationDetails.connectors[0].connector_id
					);
					const res = await request;
					console.log(res);
					// setChargeState(res.data.remarks);
				}
			});
		}
	}, [ChargerStationDetails]);

	const GetCurrentDate = () => {
		const dateObject = new Date();

		// Get date components
		const year = dateObject.getFullYear();
		const month = (dateObject.getMonth() + 1).toString().padStart(2, "0");
		const day = dateObject.getDate().toString().padStart(2, "0");

		// Formatted date
		const formattedDate = `${year}-${month}-${day}`;

		return formattedDate;
	};

	const getCurrentTimeWithSeconds = () => {
		const now = new Date();
		const hours = now.getHours().toString().padStart(2, "0");
		const minutes = now.getMinutes().toString().padStart(2, "0");
		const seconds = now.getSeconds().toString().padStart(2, "0");

		return `${hours}:${minutes}:${seconds}`;
	};

	const toggleConnectorSelect = (connectorID) => {
		setSubmodal(true, "submodal_charge_now", {
			ConnectorID: connectorID,
			LocationID: ChargerStationDetails.location_id,
			EVSEuID: ChargerStationDetails.evse_uid,
		});
	};

	return (
		<Box sx={{ height: "90%" }}>
			<Box sx={{ padding: "8px", height: "100%" }}>
				{IsLoading ? (
					<Box sx={{ height: "100%" }}>
						<Box
							sx={{
								display: "flex",
								justifyContent: "center",
								alignItems: "center",
								backgroundColor: "gainsboro",
								width: "100%",
								height: "100%",
							}}>
							<Box sx={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
								<Typography variant='subtitle1'>Fetching Charger Details</Typography>
								<CircularProgress />
							</Box>
						</Box>
					</Box>
				) : (
					<Box sx={{ height: "100%", display: "flex", flexDirection: "column", gap: 1 }}>
						<Box
							sx={{
								width: "100%",
								boxShadow: "0 1px 6px rgba(0, 0, 0, 0.12), 0 1px 4px rgba(0, 0, 0, 0.24)",
								minHeight: "35lvh",
								borderRadius: "16px",
								display: "flex",
								flexDirection: "column",
								justifyContent: "center",
								alignItems: "center",
							}}>
							<Typography
								variant='h5'
								color={"black"}
								sx={{ textAlign: "center" }}>
								Welcome!
							</Typography>
							<Typography
								variant='h4'
								color={"#F29425"}
								sx={{ textAlign: "center", fontWeight: "bold" }}>
								{ChargerStationDetails !== undefined && ChargerStationDetails.location} Charging Station
							</Typography>
							<Typography
								color={"black"}
								variant='h6'>
								Station #:{" "}
								<span style={{ color: "#3071F6" }}>
									{ChargerStationDetails !== undefined && ChargerStationDetails.station}
								</span>
							</Typography>
							<Typography
								color={"black"}
								sx={{ textAlign: "center" }}
								variant='caption'>
								{ChargerStationDetails !== undefined && ChargerStationDetails.location_address}
							</Typography>
						</Box>

						<Box
							sx={{
								width: "100%",
								boxShadow: "0 1px 6px rgba(0, 0, 0, 0.12), 0 1px 4px rgba(0, 0, 0, 0.24)",
								height: "auto",
								borderRadius: "16px",
								display: "flex",
								flexDirection: "column",
								padding: "16px",
							}}>
							<Typography
								variant='subtitle1'
								color={"black"}
								sx={{ fontWeight: "bold" }}>
								Charger Details
							</Typography>
							<Box sx={{ display: "flex", paddingX: "8px", flexDirection: "column" }}>
								<Box sx={{ display: "flex", paddingX: "16px", justifyContent: "space-between" }}>
									<Typography variant='subtitle2'>Status:</Typography>
									<Typography
										variant='subtitle2'
										color={
											ChargerStationDetails !== undefined && ChargerStationDetails.evse_status === "OFFLINE"
												? "red"
												: "green"
										}>
										{ChargerStationDetails !== undefined && ChargerStationDetails.evse_status}
									</Typography>
								</Box>
								<Box sx={{ display: "flex", paddingX: "16px", justifyContent: "space-between" }}>
									<Typography variant='subtitle2'>Power Type:</Typography>
									<Typography variant='subtitle2'>
										{ChargerStationDetails !== undefined && ChargerStationDetails.meter_type}
									</Typography>
								</Box>
								<Box sx={{ display: "flex", paddingX: "16px", justifyContent: "space-between" }}>
									<Typography variant='subtitle2'>Energy:</Typography>
									<Typography variant='subtitle2'>
										{ChargerStationDetails !== undefined && ChargerStationDetails.kwh} KW-H
									</Typography>
								</Box>
							</Box>
						</Box>
						<Box
							sx={{
								boxShadow: "0 1px 6px rgba(0, 0, 0, 0.12), 0 1px 4px rgba(0, 0, 0, 0.24)",
								borderRadius: "16px",
								padding: "16px",
							}}>
							<Typography sx={{ fontWeight: "bold", marginBottom: "8px" }}>Select Connector to use:</Typography>
							<Box className='flex flex-col gap-2'>
								{ChargerStationDetails !== "" &&
									ChargerStationDetails.connectors.map((data) => {
										return (
											<Button
												className='toggle-btn'
												disabled={data.status !== "AVAILABLE"}
												sx={{
													boxShadow: "0 1px 6px rgba(0, 0, 0, 0.12), 0 1px 4px rgba(0, 0, 0, 0.24)",
													textTransform: "none",
													justifyContent: "space-between",
													alignItems: "unset",
													color: "black",
													backgroundColor:
														data.status === "AVAILABLE"
															? "#E2F8E8"
															: data.status === "CHARGING"
															? "#DDEDFD"
															: data.status === "RESERVED"
															? "#FFEDB5"
															: "gainsboro",
													"&[aria-pressed=false]": {
														backgroundColor:
															data.status === "AVAILABLE"
																? "#E2F8E8"
																: data.status === "CHARGING"
																? "#DDEDFD"
																: data.status === "RESERVED"
																? "#FFEDB5"
																: "gainsboro",
													},
												}}
												fullWidth
												variant='outlined'
												onClick={() => toggleConnectorSelect(data.connector_id)}>
												<Typography
													variant='h2'
													fontWeight={"bold"}>
													A
												</Typography>
												<Box sx={{ display: "flex", flexDirection: "column", alignItems: "flex-end" }}>
													<Typography
														variant='subtitle2'
														sx={{
															color:
																data.status === "AVAILABLE"
																	? "green"
																	: data.status === "CHARGING"
																	? "blue"
																	: data.status === "RESERVED"
																	? "#FC5622"
																	: "gainsboro",
														}}>
														{data.status}
													</Typography>
													<Typography variant='caption'>{data.connector_type.replace("_", " ")}</Typography>
													<Typography variant='caption'>{data.energy_amount}</Typography>
												</Box>
											</Button>
										);
									})}
							</Box>
						</Box>
					</Box>
				)}
			</Box>
		</Box>
	);
};

export default ModalQRScanner;
