import { useEffect, useState, useContext } from "react";
import "../css/mainBody.css";
import Category from "./sub_components/Category";

import GetNearbyMerchants from "../../../functions/GetNearbyMerchants";
import DecryptData from "../../../functions/DecryptData";
import { renderTheme } from "../../../App";

const MainBody = (props) => {
	const [MerchantList, setMerchantList] = useState();
	const [AccessToken, setAccessToken] = useState();
	const [IsModifiedFav, setIsModifiedFav] = useState(false);
	const [NearbyMerchantChange, setNearbyMerchantChange] = useState(false);
	const [UserPosition, setUserPosition] = useState();
	const [IsFilterEnabled, setIsFilterEnabled] = useState();
	const [IsLoaded, setIsLoaded] = useState(false);
	const [DisplayHeight, setDisplayHeight] = useState("");

	// RENDER THEME
	const { RenderType, StatusChange } = useContext(renderTheme);

	// useEffect(() => {
	// 	if (RenderType === "application") {
	// 		if (StatusChange !== undefined) {
	// 			setDisplayHeight("67.5vh");
	// 		}
	// 	}
	// 	RenderType === "application"
	// 		? StatusChange !== undefined
	// 			? "67.5"
	// 			: "92.5vh"
	// 		: StatusChange !== undefined
	// 		? "77.5"
	// 		: "82.5vh";
	// 	console.log(StatusChange);
	// }, [StatusChange, RenderType]);

	useEffect(() => {
		if (props.onUserPosition !== undefined) {
			if (RenderType === "application") {
				if (window.ReactNativeWebView) {
					window.ReactNativeWebView.postMessage(
						JSON.stringify({
							type: "WEBAPP_LOGS",
							message: "NOTICE: OPEN IN APPLICATION -website",
						})
					);
				}
				let savedToken = DecryptData("access_token");

				if (savedToken !== null) {
					setAccessToken(savedToken);
					console.log("[ACCESS TOKEN] ====================> ", savedToken);
					setIsLoaded(false);
					if (props.onWhatModal === "modal_loading") {
						props.onModalVisibility(false, "");
					}
				}
			} else {
				if (RenderType === "website") {
					if (props.onIfLoggedIn === false) {
						let request = GetNearbyMerchants(
							"Basic " + process.env.REACT_APP_API_KEY,
							props.onUserPosition.lng,
							props.onUserPosition.lat,
							"guest_user"
						);
						if (!IsLoaded) {
							props.onModalVisibility(true, "modal_loading");
						} else {
							props.onModalVisibility(false, "");
						}

						console.log(request);
						request.then((res) => {
							setMerchantList(null);
							console.log("[*]:", res);
							if (res.errors === undefined) {
								setIsLoaded(true);
								setMerchantList(res.data.locations);
								props.onModalVisibility(false, "");
							} else {
								console.log("Got some error/s.");
							}
						});
					} else {
						let savedToken = DecryptData("access_token");

						if (savedToken !== null) {
							setAccessToken(savedToken);
							console.log("[ACCESS TOKEN] ====================> ", savedToken);
							setIsLoaded(false);
							if (props.onWhatModal === "modal_loading") {
								props.onModalVisibility(false, "");
							}
						}
					}
				}
			}
		}
	}, [props.onIfLoggedIn, props.onUserPosition]);

	useEffect(() => {
		// alert(AccessToken);
		if (AccessToken !== undefined) {
			let request = GetNearbyMerchants(
				"Bearer " + AccessToken,
				props.onUserPosition.lng,
				props.onUserPosition.lat,
				"registered_user"
			);

			if (!IsLoaded) {
				props.onModalVisibility(true, "modal_loading");
			}

			request.then((res) => {
				setMerchantList(null);
				console.log("[*]:", res);
				if (res.errors === undefined) {
					setIsLoaded(true);
					setMerchantList(res.data.location_with_favorites);
					props.onModalVisibility(false, "");
				} else {
					console.log("Got some error/s.");
				}
			});
		}
	}, [AccessToken]);

	useEffect(() => {
		if (props.onIsFilterEnabled !== undefined) {
			setIsFilterEnabled(props.onIsFilterEnabled);
		}
	}, [props.onIsFilterEnabled]);

	useEffect(() => {
		if (NearbyMerchantChange) {
			let savedToken = DecryptData("access_token");
			if (savedToken !== null) {
				setMerchantList();
				let request2 = GetNearbyMerchants(
					"Bearer " + savedToken,
					props.onUserPosition.lng,
					props.onUserPosition.lat,
					"registered_user"
				);

				request2.then((res) => {
					setMerchantList(null);
					console.log("[*]:", res);
					if (res.errors === undefined) {
						setMerchantList(res.data.location_with_favorites);
						setNearbyMerchantChange(false);
						props.onModalVisibility(false, "");
					} else {
						console.log("Got some error/s.");
					}
				});
			}
		}
	}, [NearbyMerchantChange]);

	const toggleIsModifiedFav = (bool) => {
		setIsModifiedFav(bool);
	};

	const toggleNearbyChange = (bool) => {
		setNearbyMerchantChange(bool);
	};

	return (
		<div
			id='home_mainBody'
			style={{
				height:
					RenderType === "application"
						? StatusChange !== undefined
							? "77.5vh"
							: "92.5vh"
						: StatusChange !== undefined
						? "75vh"
						: "90vh",
			}}>
			{IsFilterEnabled ? (
				<Category
					onWhatTitle='Filter'
					onWhatActiveCategory='Filter'
					onIfLoggedIn={props.onIfLoggedIn}
					onModalVisibility={props.onModalVisibility}
					onItemCount={props.onFilteredData ? props.onFilteredData.length : 0}
					onFilteredData={props.onFilteredData}
					onUserPosition={props.onUserPosition}
					onMerchantSelection={props.onMerchantSelection}
					onSetCatMerchantList={props.onSetCatMerchantList}
					onIsModifiedFav={IsModifiedFav}
					onSetIsModifiedFav={toggleIsModifiedFav}
				/>
			) : (
				<>
					{props.onIfLoggedIn &&
						(props.onWhatActiveCategory === "Favorite" || props.onWhatActiveCategory === "Recents") && (
							<Category
								onWhatTitle='Recents_Favorite'
								onWhatActiveCategory={props.onWhatActiveCategory}
								onIfLoggedIn={props.onIfLoggedIn}
								onModalVisibility={props.onModalVisibility}
								onItemCount={props.onCategoryMerchantList ? props.onCategoryMerchantList.length : 0}
								onCatMerchantList={props.onCategoryMerchantList}
								onSetCatMerchantList={props.onSetCatMerchantList}
								onUserPosition={props.onUserPosition}
								onIsModifiedFav={IsModifiedFav}
								onSetIsModifiedFav={toggleIsModifiedFav}
								onSetNearbyMerchantChange={toggleNearbyChange}
								onMerchantSelection={props.onMerchantSelection}
							/>
						)}
					<Category
						onWhatTitle='Nearby'
						onIfLoggedIn={props.onIfLoggedIn}
						onModalVisibility={props.onModalVisibility}
						onNearbyMerchantList={MerchantList}
						onItemCount={MerchantList ? MerchantList.length : 0}
						onSetCatMerchantList={props.onSetCatMerchantList}
						onUserPosition={props.onUserPosition}
						onIsModifiedFav={IsModifiedFav}
						onSetIsModifiedFav={toggleIsModifiedFav}
						onWhatActiveCategory={props.onWhatActiveCategory}
						onMerchantSelection={props.onMerchantSelection}
					/>
				</>
			)}
		</div>
	);
};

export default MainBody;
