import InfoIcon from "@mui/icons-material/Info";
import HelpIcon from "@mui/icons-material/Help";
import { useEffect, useState } from "react";
// import Bowser from "bowser";

const ModalLocationPrompt = (props) => {
	const [UserBrowser, setUserBrowser] = useState("");

	// useEffect(() => {
	// 	const browser = Bowser.getParser(window.navigator.userAgent);
	// 	const browserInfo = browser.getResult();

	// 	setUserBrowser(browserInfo.browser.name);
	// }, []);

	const handleGetLocation = () => {
		console.log(navigator.geolocation);
		if (navigator.geolocation) {
			navigator.geolocation.getCurrentPosition(
				(position) => {
					const { latitude, longitude } = position.coords;
					props.onSetUserLocation({
						lat: latitude,
						lng: longitude,
					});
				}
				// (error) => {
				// 	setError(`Error getting location: ${error.message}`);
				// }
			);
			// setLocationRequested(true);
		} else {
			// setError("Geolocation is not supported by this browser.");
		}
	};

	const handleResetLocationPermission = () => {
		window.open(
			"https://docs.buddypunch.com/en/articles/919258-how-to-enable-location-services-for-chrome-safari-edge-and-android-ios-devices-gps-setting",
			"_blank"
		);
	};

	return (
		<div id='modal_location'>
			<div className='modal-card card-location'>
				<h6 className='location-header'>Fetching Location Failed</h6>
				<div className='location-content'>
					<span>Can't get user's current location.</span>
					<span style={{ width: "75%" }}>
						Try refreshing the page or check if location permission is enabled/allowed in your settings, then refresh
						the page.
					</span>
					<span
						className='btn-guide'
						onClick={handleResetLocationPermission}>
						Follow Guide
						<HelpIcon />
					</span>
					<button
						className='btn btn-primary'
						type='button'
						onClick={() => window.location.reload()}>
						Try Again
					</button>
				</div>
			</div>
		</div>
	);
};

export default ModalLocationPrompt;
