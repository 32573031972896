const ValidateRegistrationData = async (type, data) => {
    const myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("Accept", "application/json");
    myHeaders.append("Authorization", "Basic " + process.env.REACT_APP_API_KEY);
    myHeaders.append("Cookie", "vsid=931vr463105325321311629");

    const requestOptions = {
        method: "GET",
        headers: myHeaders,
        redirect: "follow"
    };

    let response;

    try {
        response = await fetch(
            `${process.env.REACT_APP_API_ADDRESS}/registration/api/v1/check/${type}/${data}`,
            requestOptions
        );

        return response.json()
    } catch (error) {
        console.log("Network error or other unexpected issue:", error);
    }
}

export default ValidateRegistrationData;