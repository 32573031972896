import React, { useEffect, useState, createContext } from "react";
import { useLocation } from 'react-router-dom';


import Home from "./pages/Home/Home";
import Modal from "./pages/Modals/Modal";
import SubModal from "./pages/SubModals/SubModal";
import MessageAlert from "./pages/Alerts/Alert";
import TransactionManager from "./pages/PaymentMethod/TransactionManager";

import RefreshToken from "./functions/RefreshToken";

import GetReservationList from "./functions/GetReservationList";
import CheckPlugoutDateTime from "./functions/CheckPlugoutDateTime";
import EncryptData from "./functions/EncryptData";
import DecryptData from "./functions/DecryptData";
import CancelExpiredReservations from "./functions/CancelExpiredReservations";
import GetUserDetails from "./functions/GetUserDetails";
import GcashPay from "./functions/GcashPay";
import MayaPay from "./functions/MayaPay";
import VerifyTransaction from "./functions/VerifyTransaction";
import GetTransactionHistory from "./functions/GetTransactionHistory";
import WebViewManager from "./functions/Webview/manager";

import ChargerSocket from "./functions/Socket";

// import { socket } from "./socket";

export const renderTheme = createContext({});

const App = () => {
  const [isMobile, setIsMobile] = useState(false);
  const [UserDetails, setUserDetails] = useState();
  const [UserPosition, setUserPosition] = useState();
  const [UserAppLocation, setUserAppLocation] = useState();
  const [CurrentPage, setCurrentPage] = useState("Home");
  const [IsLoggedIn, setIsLoggedIn] = useState(false);
  const [ModalVisible, setModalVisible] = useState(false);
  const [SubModalVisible, setSubModalVisible] = useState(false);
  const [AlertVisible, setAlertVisible] = useState(false);
  const [WhatModal, setWhatModal] = useState("");
  const [WhatSubModal, setWhatSubModal] = useState();
  const [PaymentMethod, setPaymentMethod] = useState("");
  const [WhatAlertMessage, setWhatAlertMessage] = useState("");
  const [AlertSeverity, setAlertSeverity] = useState("");
  const [NotSelected, setNotSelected] = useState(false);
  const [MerchantToLocate, setMerchantToLocate] = useState(false);
  const [RenderType, setRenderType] = useState("");
  const [IsTCPPChecked, setIsTCPPChecked] = useState(false);
  const [MerchantStationSlotData, setMerchantStationSlotData] = useState();
  const [SlotReservationData, setSlotReservationData] = useState();
  const [SelectedMerchantDetails, setSelectedMerchantDetails] = useState();
  const [IsFilterEnabled, setIsFilterEnabled] = useState();
  const [FilteredData, setFilteredData] = useState();
  const [ActiveFilters, setActiveFilters] = useState();
  const [ChargingReservationDetails, setChargingReservationDetails] = useState();
  const [LiveChargingDetails, setLiveChargingDetails] = useState();
  const [ChargeStep, setChargeStep] = useState();
  const [PaymentResult, setPaymentResult] = useState();
  const [EvseUID, setEvseUID] = useState();


  const [RefreshSlot, setRefreshSlot] = useState(() => { });
  const [ToExpand, setToExpand] = useState(() => { });

  const [SubModalType, setSubModalType] = useState("")
  const [IsSubModalClicked, setIsSubModalClicked] = useState(false);
  const [IsRefreshed, setIsRefreshed] = useState();

  const location = useLocation();

  // SOCKET STATE
  const [StatusChange, setStatusChange] = useState();
  const [socket, setSocket] = useState(null);
  const [isConnected, setIsConnected] = useState(socket !== null && socket.connected)

  const ClearState = () => {
    setIsLoggedIn(false)
    localStorage.clear()
  }

  const toggleStatusChange = (status) => {
    setStatusChange(status);
  }

  const toggleSubmodalClicked = (bool) => {
    setIsSubModalClicked(bool);
  }

  const handleSelectedMerchantDetails = (details) => {
    setSelectedMerchantDetails(details);
  }

  const toggleFilterActivity = (status, data) => {
    setIsFilterEnabled(status);
    setFilteredData(IsLoggedIn ? data.filter_locations_with_favorites : data.filter_locations);
  }

  const toggleActiveFilters = (data) => {
    setActiveFilters(data);
  }

  const toggleCurrentPage = (whatPage) => {
    setCurrentPage(whatPage)
  }

  const toggleNotSelected = (bool) => {
    setNotSelected(bool);
  }

  const toggleLogin = (event, res) => {
    setIsLoggedIn(res)
  }

  const toggleModal = (visibility, whatModal) => {
    if (window.ReactNativeWebView) {
      window.ReactNativeWebView.postMessage(
        JSON.stringify({
          type: "WEBAPP_UPDATE",
          title: "MODAL_UPDATE",
          data: visibility,
        })
      );
    }

    setModalVisible(visibility);
    setWhatModal(whatModal);
  }

  const toggleSubModal = (visibility, whatSubModal, merchantStationData = undefined, reservationData = undefined, chargerFunction = undefined, toggleExpand = undefined, merchantID = undefined, addon = undefined) => {
    if (window.ReactNativeWebView) {
      window.ReactNativeWebView.postMessage(
        JSON.stringify({
          type: "WEBAPP_UPDATE",
          title: "SUBMODAL_UPDATE",
          data: visibility,
        })
      );
    }

    setSubModalVisible(visibility);
    setWhatSubModal(whatSubModal);
    if (addon !== undefined) {
      setSubModalType(addon)
    }

    if (merchantStationData !== undefined) {
      console.log(merchantStationData)
      setMerchantStationSlotData(merchantStationData);
    }

    if (reservationData !== undefined) {
      setSlotReservationData(reservationData)
    }

    // panel, evc_id, merchantID
    if (chargerFunction !== undefined && toggleExpand !== undefined && merchantID !== undefined && merchantStationData !== undefined) {
      setToExpand(() => (data) => toggleExpand(data));
      setRefreshSlot((data1, data2, data3, data4) => chargerFunction(data1, data2, data3, data4));
    }

  }

  const toggleAlert = (visibility, whatAlertMessage, AlertSeverity) => {
    setAlertVisible(visibility);
    setWhatAlertMessage(whatAlertMessage);
    setAlertSeverity(AlertSeverity);
  }

  const toggleMerchantLocate = (bool, coordinates) => {
    setMerchantToLocate({ status: bool, location: coordinates });
  }

  const togglePaymentResult = (result) => {
    setPaymentResult(result);
  }

  const toggleUserDetails = (details) => {
    setUserDetails(details);
  }

  useEffect(() => {
    console.log(ActiveFilters)
  }, [ActiveFilters])

  useEffect(() => {
    console.log("Component Mounted");
    let request = CancelExpiredReservations();
    request.then((res) => {
      console.log(res);
    });

    const handleResize = () => {
      const screenWidth = window.innerWidth;
      setIsMobile(screenWidth <= 768); // Adjust the threshold as needed
    };

    const handleMessage = (res) => {
      if (res.type === "APP_TO_WEB_LOCATION") {
        // alert(res.data.latitude)
        setRenderType("application");
        setUserPosition({
          lat: res.data.latitude,
          lng: res.data.longitude
        })
        setUserAppLocation({
          location: {
            lat: res.data.latitude,
            lng: res.data.longitude
          },
          accuracy: res.data.accuracy
        })
        setIsLoggedIn(true);
      } else {
        WebViewManager(res, toggleModal, toggleSubModal);
      }

    };

    // Initial check on mount
    handleResize();

    // Add event listener for window resize
    window.addEventListener("resize", handleResize);
    window.addEventListener('message', (event) => {
      if (event) {
        if (event.origin !== window.origin) {
          return;
        }

        if (window.ReactNativeWebView) {
          handleMessage(event.data)
          window.ReactNativeWebView.postMessage(JSON.stringify(event.data));
        }
      }
    });

    let renderType = location.pathname;
    console.log(renderType)
    if (renderType !== "/webview") {
      if (!IsLoggedIn) {
        const saved_refreshToken = localStorage.getItem('refresh_token');

        if (saved_refreshToken !== null) {
          let request = RefreshToken(DecryptData('refresh_token'));
          request.then((res) => {
            console.log("RESULT ======================> ", res)
            if (res.status === 200) {
              setIsLoggedIn(true);
            } else {
              setIsLoggedIn(false);
            }
          })
        } else {
          setIsLoggedIn(false);
        }
        setRenderType("website");
      }
    }
  }, [])

  useEffect(() => {
    // Extract the transactionID from the query string
    const searchParams = new URLSearchParams(location.search);
    const transactionID = searchParams.get('transactionID');

    // Check if transactionID exists
    if (transactionID) {
      setCurrentPage("Verification");
      let request = VerifyTransaction(transactionID);
      request.then((res) => {
        setTimeout(() => {
          if (window.ReactNativeWebView) {
            window.ReactNativeWebView.postMessage(
              JSON.stringify({
                type: "WEBAPP_ACTION",
                title: "RESULT_TOPUP_WEBVIEW",
                data: {
                  result: res.data.topup_status,
                },
              })
            );
          } else {
            toggleModal(true, "modal_sidemenu");
            toggleSubModal(true, "submodal_payment_method");
            setPaymentResult(res.data.topup_status);
            // Get the current URL without the search parameter
            const urlWithoutParam = window.location.origin + window.location.pathname;
            // Use history.pushState() to replace the current URL without the search parameter
            window.history.pushState({}, document.title, urlWithoutParam);
          }
        }, 500)
      })
    }
  }, [location.search]); // Execute only when location.search changes

  useEffect(() => {
    const handleUserAndPlugoutDetails = async (savedToken) => {
      if (savedToken !== null) {
        try {
          // Wait for the plug-out request to finish
          let plugRes = await CheckPlugoutDateTime(savedToken);

          // If there's a valid meter stop value, set the charging reservation details
          if (plugRes.data !== null && plugRes.data.meter_stop_value !== null) {
            setChargingReservationDetails({
              evse_uid: plugRes.data.evse_uid,
              connector_id: plugRes.data.evse_connector_id,
              location: plugRes.data.name,
              timeslot: {
                start: plugRes.data.timeslots[0].start,
                end: plugRes.data.timeslots[0].end
              },
              is_overstaying: true
            });
            console.log("[PLUG STATUS]:", plugRes);
          } else {
            // If no valid meter stop value, proceed to get reservation list
            let res1 = await GetReservationList(savedToken);
            console.log("[Reservation_List]:", res1);
            if (res1 !== undefined) {
              setChargingReservationDetails(res1.data);
            }
          }

          // Once the plug-out check is complete, proceed to fetch user details
          let userRes = await GetUserDetails(savedToken);
          setUserDetails(userRes.data);

        } catch (error) {
          console.error("Error occurred while fetching data:", error);
        }
      }
    };


    if (IsLoggedIn) {
      let savedToken = DecryptData("access_token");

      if (savedToken !== null) {
        handleUserAndPlugoutDetails(savedToken)
      }
    }

  }, [IsLoggedIn, StatusChange])

  useEffect(() => {
    console.log("[CHARGING_RESERVATION_DETAILS]:", ChargingReservationDetails)
    if (ChargingReservationDetails !== undefined && ChargingReservationDetails.reservations !== undefined && ChargingReservationDetails.reservations.length > 0) {
      if (ChargingReservationDetails.is_charging === 1) {
        console.log("[Y]:", ChargingReservationDetails)
        let tmp_socket = ChargerSocket(ChargingReservationDetails.evse_uid, ChargingReservationDetails.connector_id);
        setSocket(tmp_socket)
        setStatusChange("USER_CHARGING");
        if (ChargeStep === undefined || ChargeStep === "") {
          setChargeStep("INITIALIZATION")
        }

      } else {
        console.log("[X]:", ChargingReservationDetails)
        if (ChargingReservationDetails.reservations.length > 0) {
          if (ChargingReservationDetails.reservations[0].status === "VIRTUAL_ACTIVE") {
            console.log("[Z]:", ChargingReservationDetails.reservations[0].evse_uid)
            let tmp_socket = ChargerSocket(ChargingReservationDetails.reservations[0].evse_uid, ChargingReservationDetails.reservations[0].connector_id);
            setSocket(tmp_socket)
            setStatusChange("USER_CHARGING");
          } else {
            setStatusChange("USER_RESERVATION")
          }
        }

      }
    } else {
      if (ChargingReservationDetails?.is_charging === 1 || ChargingReservationDetails?.is_overstaying) {
        console.log("[Z]:", ChargingReservationDetails)
        console.log("[A]:", ChargingReservationDetails)
        let tmp_socket = ChargerSocket(ChargingReservationDetails.evse_uid, ChargingReservationDetails.connector_id);
        setSocket(tmp_socket)
        if (ChargingReservationDetails?.is_overstaying) {
          setStatusChange("USER_OVERSTAYING");
          if (ChargeStep === undefined || ChargeStep === "") {
            setChargeStep("OVERSTAYING")
          }

        } else {
          setStatusChange("USER_CHARGING");
          if (ChargeStep === undefined) {
            setChargeStep("INITIALIZATION")
          }
        }
      } else {
        setStatusChange()
        if (WhatModal !== "modal_charging_reservation_details") {
          setChargeStep("")
        }
      }
    }


  }, [ChargingReservationDetails])

  useEffect(() => {
    console.log("SELECTED MERCHANT: ", SelectedMerchantDetails)
  }, [SelectedMerchantDetails])

  useEffect(() => {
    console.log(ChargeStep)
    if (StatusChange === "USER_CHARGING" || StatusChange === "USER_OVERSTAYING") {
      if (socket !== null) {
        socket.connect();

        if (ChargeStep === undefined || ChargeStep === "") {
          if (ChargingReservationDetails.is_charging !== 1) {
            setChargeStep("WAITING");
          }
        }
        setIsConnected(true);
      }
    }

    if (socket !== null && isConnected) {
      function handleChargerStatus(data) {
        console.log("[CHARGER DATA]:", data, "[STATUS]:", StatusChange, "[CHARGE STEP]:", ChargeStep)
        if (ChargeStep !== "CHARGING" || ChargeStep !== "COMPLETE" || ChargeStep !== "OVERSTAYING") {
          setChargeStep("WAITING");
        }

        if (data.status === "UNPLUGGED-ONLINE" || data.status === "ONLINE" || data.status === "VIRTUAL_ACTIVE") {
          if (ChargingReservationDetails?.reservations?.length > 0) {
            setChargeStep("WAITING");
          } else {
            toggleModal(true, "modal_charging_reservation_details")
            setChargeStep("COMPLETE");
            setStatusChange();
            setTimeout(() => {
              socket.disconnect();
              setIsConnected(false);
            }, 1000)
          }
        }

        if (data.status === "PLUGGED-IN" || data.status === "CHARGING") {
          setChargeStep("PLUGGED-IN");
          setTimeout(() => {
            if (data.status === "CHARGING") {
              setChargeStep("INITIALIZATION");
            }
          }, 3000)
        }

        console.log("[Charger_Status]:", data);
      };

      function handleChargingStatus(data) {
        if (setChargeStep !== "CHARGING") {
          setChargeStep("CHARGING");
        }
        setLiveChargingDetails(data);
        console.log("[Charging_Status]:", data);
      }

      function handleChargingStopDetails(data) {
        setChargeStep("OVERSTAYING")
        setStatusChange("USER_OVERSTAYING");
        setLiveChargingDetails(data);
        console.log("[Charging_Stop_Details]:", data);
      }

      console.log(socket)
      console.log("STATUS_CHANGE:", StatusChange)
      socket.on("charger-status", handleChargerStatus);
      socket.on("charging-status", handleChargingStatus);
      socket.on("charging-stop-details", handleChargingStopDetails)
      // Clean up: remove event listener when StatusChange changes
      return () => {
        socket.off("charger-status", handleChargerStatus);
        socket.off("charging-status", handleChargingStatus)
        socket.off("charging-stop-details", handleChargingStopDetails)
      };
    }
  }, [isConnected, socket, StatusChange])

  return (
    // <SlideComponent />
    <renderTheme.Provider value={{ isMobile, RenderType, IsTCPPChecked, setIsTCPPChecked, StatusChange, toggleStatusChange, ChargingReservationDetails, setChargingReservationDetails, UserPosition, toggleActiveFilters, ActiveFilters, toggleSubmodalClicked, LiveChargingDetails, UserDetails, ChargeStep, setChargeStep, AlertVisible, PaymentResult, togglePaymentResult, ClearState }}>
      {AlertVisible && <MessageAlert onAlertVisibility={toggleAlert} alertDetails={{ message: WhatAlertMessage, severity: AlertSeverity }} />}

      {SubModalVisible && <SubModal onWhatSubModal={WhatSubModal} onModalVisibility={toggleModal} onSubModalVisibility={toggleSubModal} onPaymentMethod={PaymentMethod} onAlertVisibility={toggleAlert} onIsLoggedIn={toggleLogin} onWhatSlotData={MerchantStationSlotData} onWhatReservationData={SlotReservationData} onExpand={ToExpand} onRefreshSlot={RefreshSlot} onWhatType={SubModalType} />}

      {ModalVisible && <Modal onWhatModal={WhatModal} onWhatSubModal={WhatSubModal} onModalVisibility={toggleModal} onSubModalVisibility={toggleSubModal} onIfLoggedIn={IsLoggedIn} onIsLoggedIn={toggleLogin} onAlertVisibility={toggleAlert} onSetSelected={toggleNotSelected} onMerchantToLocate={toggleMerchantLocate} onSetAsCurrent={toggleCurrentPage} onUserAppLocation={UserAppLocation} onUserLocation={UserPosition} onSetUserLocation={setUserPosition} onSelectedMerchant={SelectedMerchantDetails} onSetFilter={toggleFilterActivity} onIsSubmodalVisible={SubModalVisible} />}

      {CurrentPage === "Home" ? <Home onWhatModal={WhatModal} onSetAsCurrent={toggleCurrentPage} onIfLoggedIn={IsLoggedIn} onModalVisibility={toggleModal} onAlertVisibility={toggleAlert} onSetSelected={toggleNotSelected} onIfSelected={NotSelected} onMerchantToLocate={toggleMerchantLocate} onIsMerchantToLocate={MerchantToLocate} onUserAppLocation={UserAppLocation} onUserLocation={UserPosition} onSetUserLocation={setUserPosition} onMerchantSelection={handleSelectedMerchantDetails} onIsFilterEnabled={IsFilterEnabled} onFilteredData={FilteredData} /> : <TransactionManager />}

    </renderTheme.Provider>
  );
}

export default App;
