import RefreshToken from './RefreshToken';
import DecryptData from './DecryptData';

const GetUserDetails = async (accessToken) => {
    const myHeaders = new Headers();
    myHeaders.append("Authorization", "Bearer " + accessToken);

    const requestOptions = {
        method: "GET",
        headers: myHeaders,
        redirect: "follow"
    };

    let response;

    try {
        response = await fetch(
            `${process.env.REACT_APP_API_ADDRESS}/login/api/v1/accounts/users/info`,
            requestOptions
        );

        if (!response.ok) {
            const responseData = await response.json();

            // Handle 401 or 403 (Unauthorized or Forbidden)
            if (response.status === 401 || response.status === 403) {
                console.log("Access token expired or unauthorized. Attempting to refresh...");

                // Decrypt and retrieve the refresh token from localStorage
                const saved_refreshToken = DecryptData('refresh_token');

                if (saved_refreshToken) {
                    // Try to refresh the token
                    const refreshResponse = await RefreshToken(saved_refreshToken);

                    if (refreshResponse.status === 200) {
                        // Successfully refreshed the token, update the access token and retry the request
                        const newAccessToken = refreshResponse.data.access_token;
                        console.log("New access token: ", newAccessToken);

                        // Update headers with the new token
                        myHeaders.set("Authorization", "Bearer " + newAccessToken);
                        requestOptions.headers = myHeaders;

                        // Retry the request with the new token
                        response = await fetch(
                            `${process.env.REACT_APP_API_ADDRESS}/login/api/v1/accounts/users/info`,
                            requestOptions
                        );

                        const retryData = await response.json();

                        if (!response.ok) {
                            console.log(`Retry failed with status: ${response.status}`);
                            return null;
                        }
                        return retryData;  // Return retry data if successful
                    } else {
                        console.log("Failed to refresh token. Logging out...");
                        localStorage.clear();
                        return null;
                    }
                } else {
                    console.log("No refresh token available. Logging out...");
                    localStorage.clear();
                    return null;
                }
            } else {
                console.log(`Server error: ${response.status}`, responseData);
                return responseData;  // Return response data even if not 401 for further diagnostics
            }
        } else {
            return response.json();  // If successful, return the JSON response
        }
    } catch (error) {
        console.log("Network error or other unexpected issue:", error);
        throw error;  // Rethrow the error or handle it accordingly
    }
};

export default GetUserDetails;
