import "../../css/mainBody.css";
import MerchantCard from "./MerchantCard";
import { useEffect, useState, useRef, useContext } from "react";
import EmptyListIllustration from "../../../../assets/empty-list-illustration.svg";

import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import { Box, Chip, Typography } from "@mui/material";

import { renderTheme } from "../../../../App";

const Category = (props) => {
	const [MerchantList, setMerchantList] = useState();
	const [scrollX, setscrollX] = useState(0); // For detecting start scroll position
	const [scrolEnd, setscrolEnd] = useState(false);
	const scrl = useRef(null);
	const [screenWidth, setScreenWidth] = useState(window.innerWidth);
	const [ItemCount, setItemCount] = useState(0);
	const [scrollLeft, setScrollLeft] = useState(0);

	// RENDER THEME
	const { UserPosition, toggleActiveFilters, ActiveFilters } = useContext(renderTheme);

	// Update the scrollX state when the container is scrolled
	const handleScroll = () => {
		setScrollLeft(scrl.current.scrollLeft);
	};

	useEffect(() => {
		if (scrl.current) {
			scrl.current.addEventListener("scroll", handleScroll);
			return () => {
				if (scrl.current) {
					scrl.current.removeEventListener("scroll", handleScroll);
				}
			};
		}
	}, [scrl.current]);

	useEffect(() => {
		// Update scroll end state based on scroll position and total scrollable width
		if (scrl.current) {
			const isEnd = scrollLeft + scrl.current.offsetWidth >= scrl.current.scrollWidth;
			setscrolEnd(isEnd);
		}
	}, [scrollLeft, scrl.current?.offsetWidth, scrl.current?.scrollWidth]);

	useEffect(() => {
		const handleResize = () => {
			setScreenWidth(window.innerWidth);
		};

		// Attach the event listener
		window.addEventListener("resize", handleResize);

		// Remove the event listener on component unmount
		return () => {
			window.removeEventListener("resize", handleResize);
		};
	}, []);

	useEffect(() => {
		setItemCount(props.onItemCount);
	}, [props.onItemCount]);

	useEffect(() => {
		setMerchantList();
		if (props.onNearbyMerchantList !== undefined) {
			setMerchantList(props.onNearbyMerchantList);
		} else if (props.onFilteredData !== undefined) {
			setMerchantList(props.onFilteredData);
		} else {
			setMerchantList(props.onCatMerchantList);
		}
	}, [props.onNearbyMerchantList, props.onCatMerchantList, props.onFilteredData]);

	useEffect(() => {
		console.log("[ACTIVE FILTERS]: ", ActiveFilters);
	}, [ActiveFilters]);

	const toggleItemCount = (item) => {
		setItemCount((prev) => prev + item);
	};

	const slideToNextCard = () => {
		if (scrl.current) {
			const cardWidth = scrl.current.offsetWidth; // Get the width of the container
			console.log("[CARD WIDTH]:", cardWidth);
			const nextScrollLeft = Math.ceil((scrl.current.scrollLeft + cardWidth) / cardWidth) * (cardWidth * 0.906); // Align the next card with the container's left edge
			console.log("Next scrollLeft:", nextScrollLeft, scrl.current.scrollLeft);

			scrl.current.scroll({
				left: nextScrollLeft,
				behavior: "smooth", // Optional: adds smooth scrolling effect
			});
			console.log("[ITEM COUNT]:", ItemCount !== 0);
			console.log("[SCROLL CURRENT]:", scrl.current);
			console.log("[SCROLL LEFT]:", scrl.current.scrollLeft > 0);
		}
	};

	const slideToPrevCard = () => {
		if (scrl.current) {
			const cardWidth = scrl.current.offsetWidth; // Get the width of the container
			console.log("[CARD WIDTH]:", cardWidth);
			const prevScrollLeft = Math.floor(scrl.current.scrollLeft - cardWidth * 0.906); // Align the previous card with the container's left edge
			console.log("Prev scrollLeft:", prevScrollLeft);

			scrl.current.scroll({
				left: prevScrollLeft,
				behavior: "smooth", // Optional: adds smooth scrolling effect
			});
			console.log("[ITEM COUNT]:", ItemCount !== 0);
			console.log("[SCROLL CURRENT]:", scrl.current);
			console.log("[SCROLL LEFT]:", scrl.current.scrollLeft > 0);
		}
	};

	const toggleFilterSelection = () => {
		props.onModalVisibility(true, "modal_choosefilter");
	};

	return (
		<div
			className={
				props.onWhatTitle === "Nearby"
					? "mainBody-category nearby"
					: `mainBody-category ${props.onWhatActiveCategory === "Favorite" ? "favorite" : "recent"}`
			}>
			<div className='category-content'>
				{props.onWhatTitle === "Recents_Favorite" ? (
					<div>
						<span className='category-title'>
							{props.onWhatActiveCategory === "Favorite" ? "Favorite Charger Locations" : "Recent Charger Locations"}
						</span>
					</div>
				) : props.onWhatTitle === "Filter" ? (
					<Box
						sx={{
							display: "flex",
							gap: { xs: 0, lg: 2 },
							marginBottom: "8px",
							alignItems: { lg: "center" },
							flexDirection: { xs: "column", lg: "row" },
						}}>
						<span className='category-title'>{props.onWhatTitle === "Filter" && "Charger Locations Filtered by:"}</span>
						<Box
							sx={{
								paddingX: { xs: "8px", lg: 0 },
								paddingY: { xs: "8px", lg: 0 },
								gap: 1,
								display: "flex",
								flexWrap: "wrap",
							}}>
							{/* POWER TYPES */}
							{ActiveFilters.power_types.length > 0 && (
								<Chip
									sx={{ width: "max-content" }}
									label={
										<>
											<Typography
												variant='caption'
												sx={{ fontWeight: "bold" }}>
												Power Types:&nbsp;
											</Typography>
											<Typography variant='caption'>{ActiveFilters.power_types.join(", ")}</Typography>
										</>
									}
									variant='outlined'
									onClick={toggleFilterSelection}
								/>
							)}
							{/* CONNECTOR TYPES */}
							{ActiveFilters.connector_types.length > 0 && (
								<Chip
									sx={{ width: "max-content" }}
									label={
										<>
											<Typography
												variant='caption'
												sx={{ fontWeight: "bold" }}>
												Connector Types:&nbsp;
											</Typography>
											<Typography variant='caption'>{ActiveFilters.connector_types.join(", ")}</Typography>
										</>
									}
									variant='outlined'
									onClick={toggleFilterSelection}
								/>
							)}
							{/* FACILITIES */}
							{ActiveFilters.facilities.length > 0 && (
								<Chip
									sx={{ width: "max-content" }}
									label={
										<>
											<Typography
												variant='caption'
												sx={{ fontWeight: "bold" }}>
												Facilities:&nbsp;
											</Typography>
											<Typography variant='caption'>{ActiveFilters.facilities.join(", ")}</Typography>
										</>
									}
									variant='outlined'
									onClick={toggleFilterSelection}
								/>
							)}
							{/* CAPABILITIES */}
							{ActiveFilters.capabilities.length > 0 && (
								<Chip
									sx={{ width: "max-content" }}
									label={
										<>
											<Typography
												variant='caption'
												sx={{ fontWeight: "bold" }}>
												Capabilities:&nbsp;
											</Typography>
											<Typography variant='caption'>
												{ActiveFilters.capabilities.join(", ").replace("_", " ")}
											</Typography>
										</>
									}
									variant='outlined'
									onClick={toggleFilterSelection}
								/>
							)}
							{/* PARKING TYPES */}
							{ActiveFilters.parking_types.length > 0 && (
								<Chip
									sx={{ width: "max-content" }}
									label={
										<>
											<Typography
												variant='caption'
												sx={{ fontWeight: "bold" }}>
												Parking Types:&nbsp;
											</Typography>
											<Typography variant='caption'>{ActiveFilters.parking_types.join(", ")}</Typography>
										</>
									}
									variant='outlined'
									onClick={toggleFilterSelection}
								/>
							)}
							{/* PAYMENT TYPES */}
							{ActiveFilters.payment_types.length > 0 && (
								<Chip
									sx={{ width: "max-content" }}
									label={
										<>
											<Typography
												variant='caption'
												sx={{ fontWeight: "bold" }}>
												Payment Types:&nbsp;
											</Typography>
											<Typography variant='caption'>{ActiveFilters.payment_types.join(", ")}</Typography>
										</>
									}
									variant='outlined'
									onClick={toggleFilterSelection}
								/>
							)}
						</Box>
					</Box>
				) : (
					<span className='category-title'>{props.onWhatTitle === "Nearby" && "Nearby Charger Locations"}</span>
				)}
				{ItemCount === 0 ? (
					<div className='card-list-container'>
						<div className='w-100 d-flex justify-content-center category-empty-list'>
							<span>Nothing on the list.</span>
							<Typography
								variant='caption'
								sx={{ marginBottom: "16px" }}>
								Try refreshing the page to get updated Location list.
							</Typography>
							<img
								className='category-empty-list-image'
								src={EmptyListIllustration}
							/>
						</div>
					</div>
				) : (
					<div
						className='card-list-container'
						ref={scrl}>
						{MerchantList !== undefined &&
							MerchantList.map((item) => {
								return (
									<MerchantCard
										onIfLoggedIn={props.onIfLoggedIn}
										onActiveCategory={props.onWhatActiveCategory}
										onMerchantSelection={props.onMerchantSelection}
										merchantDetails={{
											name: item.name,
											address: item.address,
											distanceAway: item.distance.toFixed(2) + "km away",
											stations: item.evses,
											facilities: item.facilities,
											parking_types: item.parking_types,
											parking_restrictions: item.parking_restrictions,
											merchantID: item.id,
											favorite: item.favorite,
											address_lat: item.address_lat,
											address_lng: item.address_lng,
										}}
										onModalVisibility={props.onModalVisibility}
										onWhatCategory={props.onWhatTitle}
										onSetCatMerchantList={props.onSetCatMerchantList}
										onUserPosition={props.onUserPosition}
										onSetIsModifiedFav={props.onSetIsModifiedFav}
										onSetNearbyMerchantChange={props.onSetNearbyMerchantChange}
										onSetItemCount={toggleItemCount}
									/>
								);
							})}
					</div>
				)}
				{ItemCount !== 0 && scrl.current && !(scrl.current.scrollLeft <= 0) && (
					<button
						className='prev'
						onClick={() => slideToPrevCard()}>
						<ChevronLeftIcon />
					</button>
				)}
				{ItemCount > 1 && !scrolEnd && (
					<button
						className='next'
						onClick={() => slideToNextCard()}>
						<ChevronRightIcon />
					</button>
				)}
			</div>
		</div>
	);
};

export default Category;
