import RefreshToken from './RefreshToken';
import DecryptData from './DecryptData';

const GetFavMerchants = async (AccessToken, Longitude, Latitude) => {
    var myHeaders = new Headers();
    myHeaders.append("Accept", "application/json");
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("Authorization", "Bearer " + AccessToken);

    var graphql = JSON.stringify({
        query: `query Favorite_locations {\r\n    favorite_locations(lat: ${Latitude}, lng: ${Longitude}) {\r\n        id\r\n        name\r\n        address\r\n        address_lat\r\n        address_lng\r\n        city\r\n        date_created\r\n        date_modified\r\n        distance\r\n        favorite\r\n        evses {\r\n            uid\r\n            evse_id\r\n            serial_number\r\n            meter_type\r\n            status\r\n            cpo_location_id\r\n            current_ws_connection_id\r\n            server_id\r\n            date_created\r\n            connectors {\r\n                id\r\n                evse_uid\r\n                connector_id\r\n                standard\r\n                format\r\n                max_voltage\r\n                max_amperage\r\n                max_electric_power\r\n                status\r\n                date_created\r\n                date_modified\r\n            }\r\n        }\r\n    }\r\n}\r\n`,
        variables: {}
    });

    var requestOptions = {
        method: 'POST',
        headers: myHeaders,
        body: graphql,
        redirect: 'follow'
    };

    let response;

    try {
        // Initial request
        response = await fetch(
            `${process.env.REACT_APP_API_ADDRESS}/booking_merchants/graphql`,
            requestOptions
        );

        // Log initial response status and data
        console.log("Initial response status:", response.status);
        const responseData = await response.json();
        console.log("Initial response data:", responseData);

        // Check if the response is successful and contains errors
        if (response.ok) {
            if (responseData.errors && responseData.errors.length > 0) {
                if (responseData.errors[0].status === 401) {
                    console.log("Access token expired, attempting to refresh...");

                    // Decrypt and retrieve refresh token from localStorage
                    const saved_refreshToken = DecryptData('refresh_token');

                    if (saved_refreshToken) {
                        // Try to refresh the token
                        const refreshResponse = await RefreshToken(saved_refreshToken);

                        if (refreshResponse.status === 200) {
                            // Successfully refreshed the token, update the access token and retry the request
                            const newAccessToken = refreshResponse.data.access_token;
                            console.log("New access token: ", newAccessToken);

                            // Update headers with the new token
                            myHeaders.set("Authorization", "Bearer " + newAccessToken);
                            requestOptions.headers = myHeaders;

                            // Retry the request with the new token
                            response = await fetch(
                                `${process.env.REACT_APP_API_ADDRESS}/booking_merchants/graphql`,
                                requestOptions
                            );

                            const retryData = await response.json();
                            console.log("Retry response data:", retryData);

                            if (!response.ok) {
                                console.log(`Retry failed with status: ${response.status}`);
                                return null;
                            }
                            return retryData;  // Return the retry data if successful
                        } else {
                            console.log("Failed to refresh token. Logging out...");
                            localStorage.clear();
                            return null;
                        }
                    } else {
                        console.log("No refresh token available. Logging out...");
                        localStorage.clear();
                        return null;
                    }
                } else {
                    // Return data if there are no errors or no 401 status
                    return responseData;
                }
            } else {
                // No errors, return the response data
                return responseData;
            }
        } else {
            // Log server error if response is not okay
            console.log(`Server error: ${response.status}`);
            return null;
        }
    } catch (error) {
        console.log("Network error or other unexpected issue:", error);
        throw error;  // Rethrow the error or handle it accordingly
    }
};

export default GetFavMerchants;
