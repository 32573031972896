import React, { useEffect, useState } from "react";
import "bootstrap/dist/css/bootstrap.min.css";

import Tooltip from "@mui/material/Tooltip";
import FavoriteIcon from "@mui/icons-material/Favorite";
import FavoriteBorderIcon from "@mui/icons-material/FavoriteBorder";
import Carousel from "react-bootstrap/Carousel";

import Chip from "@mui/material/Chip";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import BoltIcon from "@mui/icons-material/Bolt";
import SettingsInputSvideoIcon from "@mui/icons-material/SettingsInputSvideo";

import GradeIcon from "@mui/icons-material/Grade";
import "../../css/mainBody.css";
import DockIcon from "@mui/icons-material/Dock";

import GetFavMerchants from "../../../../functions/GetFavMerchants";
import SetAsFav from "../../../../functions/SetAsFav";
import DelAsFav from "../../../../functions/DelAsFav";
import SelectMerchant from "../../../../functions/SelectMerchant";
import GetCurrentLocation from "../../../../functions/GetCurrentLocation";
import DecryptData from "../../../../functions/DecryptData";
import { Box, Typography } from "@mui/material";

const MerchantCard = (props) => {
	const [FavoriteMerchant, setFavoriteMerchant] = useState(false);
	const [FavoriteWasClicked, setFavoriteWasClicked] = useState(false);
	const [ChargerTypes, setChargerTypes] = useState();
	const [PlugTypes, setPlugTypes] = useState();
	const [AreCarousel, setAreCarousel] = useState(false);
	const [IsCategoryAvailable, setIsCategoryAvailable] = useState(true);

	useEffect(() => {
		if (props.merchantDetails.favorite === "true") {
			setFavoriteMerchant(true);
		}
	}, [props.merchantDetails.favorite]);

	useEffect(() => {
		if (props.merchantDetails !== undefined && props.merchantDetails.stations.length > 0) {
			setChargerTypes(getUniqueValues(props.merchantDetails.stations, "meter_type").join(", "));
			// Assuming 'data' holds the provided object

			// Create an empty array to store unique standards
			let uniqueStandards = [];
			// Iterate through each station
			props.merchantDetails.stations.map((station) => {
				// Iterate through each connector of the station
				if (station.connectors !== undefined && station.connectors.length > 0) {
					station.connectors.map((connector) => {
						let plug = connector.standard.replace("_", " ");
						// Check if the standard is not already included in the uniqueStandards array
						if (!uniqueStandards.includes(plug)) {
							// If not included, add it to the uniqueStandards array
							uniqueStandards.push(plug);
						}
					});
				}
			});
			setPlugTypes(uniqueStandards.join(", "));
		}
	}, [props.merchantDetails]);

	useEffect(() => {
		console.log(ChargerTypes);
	}, [ChargerTypes]);

	useEffect(() => {
		if (props.merchantDetails.facilities !== undefined) {
			if (props.merchantDetails.facilities.length > 0) {
				setAreCarousel(true);
			}
		}
	}, [props.merchantDetails.facilities]);

	const toggleFavoriteButton = () => {
		let savedToken = DecryptData("access_token");
		if (savedToken !== null) {
			if (FavoriteMerchant === false && props.onIfLoggedIn) {
				console.log(props.merchantDetails.merchantID);
				let request = SetAsFav(savedToken, props.merchantDetails.merchantID);
				request.then((res) => {
					if (res.data.errors === undefined) {
						if (res.data.addToFavoriteLocation.status === 200) {
							props.onSetIsModifiedFav(true);
							setFavoriteMerchant(true);
							ReRequestMerchants(savedToken);
						}
					} else {
						console.log("Got some error/s.");
					}
				});
			} else {
				let request = DelAsFav(savedToken, props.merchantDetails.merchantID);
				request.then((res) => {
					if (res.data.errors === undefined) {
						if (res.data.removeFromFavoriteLocation.status === 200) {
							props.onSetIsModifiedFav(false);
							setFavoriteMerchant(false);
							ReRequestMerchants(savedToken);
						}
					} else {
						console.log("Got some error/s.");
					}
				});
			}
		}
	};

	const ReRequestMerchants = (reqToken) => {
		if (props.onWhatCategory === "Recents_Favorite") {
			props.onSetItemCount(-1);
			setIsCategoryAvailable(false);
			props.onSetNearbyMerchantChange(true);
		} else {
			if (props.onActiveCategory === "Favorite") {
				let request = GetFavMerchants(reqToken, props.onUserPosition.lng, props.onUserPosition.lat);
				request.then((res) => {
					props.onSetCatMerchantList();
					if (res.data.favorite_locations.length > 0) {
						props.onSetCatMerchantList(res.data.favorite_locations);
					}
				});
			}
		}
	};

	const handleCardClick = (e) => {
		if (props.onIfLoggedIn) {
			if (
				!(e.target.localName === "path") &&
				!(e.target.className === "carousel-control-prev-icon") &&
				!(e.target.className === "carousel-control-prev") &&
				!(e.target.className === "carousel-control-next-icon") &&
				!(e.target.className === "carousel-control-next") &&
				!(e.target.className === "MuiSvgIcon-root")
			) {
				props.onModalVisibility(true, "modal_loading");
				console.log(props.merchantDetails);
				let reqLocation = GetCurrentLocation();
				reqLocation.then((data_reqLocation) => {
					let savedToken = DecryptData("access_token");
					if (savedToken !== null) {
						let request = SelectMerchant(
							savedToken,
							props.merchantDetails.merchantID,
							data_reqLocation.latitude,
							data_reqLocation.longitude
						);
						request.then((data1) => {
							props.onMerchantSelection({
								name: data1.data.location_with_favorite.name,
								address: data1.data.location_with_favorite.address,
								distanceAway: data1.data.location_with_favorite.distance.toFixed(2) + "km away",
								stations: data1.data.location_with_favorite.evses,
								facilities: data1.data.location_with_favorite.facilities,
								parking_types: data1.data.location_with_favorite.parking_types,
								parking_restrictions: data1.data.location_with_favorite.parking_restrictions,
								merchantID: data1.data.location_with_favorite.id,
								favorite: data1.data.location_with_favorite.favorite,
								address_lat: data1.data.location_with_favorite.address_lat,
								address_lng: data1.data.location_with_favorite.address_lng,
							});
							props.onModalVisibility(true, "modal_merchant");
						});
					}
				});
			} else {
				props.onModalVisibility(false, "");
			}
		} else {
			if (
				!(e.target.className === "carousel-control-prev-icon") &&
				!(e.target.className === "carousel-control-prev") &&
				!(e.target.className === "carousel-control-next-icon") &&
				!(e.target.className === "carousel-control-next")
			) {
				props.onModalVisibility(true, "modal_login");
			}
		}
	};

	const getUniqueValues = (array, key) => {
		const uniqueValues = [];
		const uniqueKeys = [];

		array.forEach((obj) => {
			const value = obj[key];

			// Check if the value is unique
			if (uniqueKeys.indexOf(value) === -1) {
				uniqueKeys.push(value);
				uniqueValues.push(value);
			}
		});

		return uniqueValues;
	};

	function importAll(r) {
		let images = {};
		r.keys().forEach((item, index) => {
			images[item.replace("./", "")] = r(item);
		});
		return images;
	}
	const getImage = importAll(require.context("../../../../assets", false, /\.(png|jpe?g|svg)$/));

	return (
		<>
			{IsCategoryAvailable && (
				<div
					className={
						props.onWhatCategory === "Recents_Favorite"
							? "merchant-card col-lg-4 col-xl-3 col-xxl-2 col-8"
							: "merchant-card col-lg-4 col-xl-3 col-xxl-2  col-11"
					}>
					<div className='card-title-holder'>
						<span
							className='description-merchant-name'
							onClick={handleCardClick}>
							{props.merchantDetails.name}
						</span>
						{props.onIfLoggedIn && (
							<Tooltip
								title={FavoriteMerchant ? "Remove from Favorites" : "Add to Favorites"}
								placement='bottom-start'>
								<span className='btn-merchant-favorite'>
									{FavoriteMerchant ? (
										<FavoriteIcon
											className='btn-favorite-active'
											onClick={toggleFavoriteButton}
											fontSize='large'
										/>
									) : (
										<FavoriteBorderIcon
											onClick={toggleFavoriteButton}
											sx={{ color: "black" }}
											fontSize='large'
										/>
									)}
								</span>
							</Tooltip>
						)}
					</div>
					<div
						className='card-image-holder gap-1'
						onClick={handleCardClick}>
						<div className='card-holder-image-list'>
							<div className='col-12 p-1'>
								<img
									src={getImage["ParkNcharge_Logo.png"]}
									className='card-image-item'
								/>
							</div>
							{/* <div className='col-12 p-1'>
								<img
									src={getImage["ThreeEcom_Image_002.jpg"]}
									className='card-image-item'
								/>
							</div>
							<div className='col-12 p-1'>
								<img
									src={getImage["ThreeEcom_Image_003.jpg"]}
									className='card-image-item'
								/>
							</div>
							<div className='col-12 p-1'>
								<img
									src={getImage["ThreeEcom_Image_004.jpg"]}
									className='card-image-item'
								/>
							</div> */}
						</div>
						<span className='merchant-rating'>
							<GradeIcon style={{ fontSize: ".85rem" }} />
							&nbsp;<span>-</span>
						</span>
					</div>
					<div
						className='card-text-holder'
						onClick={handleCardClick}>
						<Box sx={{ height: "auto", width: "100%", padding: "8px" }}>
							<div className='merchant-subdescription'>
								<span className='subdescription-address'>
									<Chip
										icon={<LocationOnIcon />}
										size='large'
										sx={{ fontWeight: "bold" }}
										label={props.merchantDetails.address}
										// variant='outlined'
									/>
									{/* <span style={{ fontWeight: "bold" }}>Address:</span> <span>{props.merchantDetails.address}</span> */}
								</span>
								<span className='subdescription-chargers mt-1'>
									<Chip
										icon={<BoltIcon />}
										size='large'
										sx={{ fontWeight: "bold" }}
										label={ChargerTypes !== undefined ? ChargerTypes : "N/A"}
										// variant='outlined'
									/>
									{/* <span style={{ fontWeight: "bold" }}>Charge Types:</span>{" "}
								<span>{ChargerTypes !== undefined ? ChargerTypes : "N/A"}</span> */}
								</span>
								<span className='subdescription-plugs mt-1'>
									<Chip
										icon={<SettingsInputSvideoIcon />}
										size='large'
										sx={{ fontWeight: "bold" }}
										label={PlugTypes ? PlugTypes : "N/A"}
										// variant='outlined'
									/>
									{/* <span style={{ fontWeight: "bold" }}>Plug Types:</span> <span>{PlugTypes ? PlugTypes : "N/A"}</span> */}
								</span>
							</div>
						</Box>
						<span className='description-merchant-available-distance'>
							<span>
								<DockIcon />
								<span> {props.merchantDetails.stations.length}</span>
							</span>
							<Typography
								fontSize={"1rem"}
								variant='caption'>
								{props.merchantDetails.distanceAway}
							</Typography>
							{/* <span style={{ fontSize: ".9rem", fontStyle: "italic", color: "gray" }}>
								{props.merchantDetails.distanceAway}
							</span> */}
						</span>
					</div>
				</div>
			)}
		</>
	);
};

export default MerchantCard;
