import { useEffect, useState, useContext, useRef } from "react";
import Header from "./components/Header";
import BookingStatus from "./components/BookingStatus";
import MainBody from "./components/MainBody";
import MapBody from "./components/MapBody";
import BottomNavigation from "./components/BottomNavigation";

import "./css/home.css";

import { renderTheme } from "../../App";

const Home = (props) => {
	const [BodyVisible, setBodyVisible] = useState("Main");
	// const [UserPosition, setUserPosition] = useState();
	const [UserMarkerVisibility, setUserMarkerVisibility] = useState(false);
	const [ZoomLevel, setZoomLevel] = useState(17);
	const [ActiveCategory, setActiveCategory] = useState("");
	const [MarkerCoordinates, setMarkerCoordinates] = useState(0);

	// RENDER THEME
	const { RenderType, StatusChange } = useContext(renderTheme);
	const [CategoryMerchantList, setCategoryMerchantList] = useState("");
	const loadingRef = useRef(null);
	const userLocateRef = useRef(null);

	const toggleActiveCategory = (category) => {
		setActiveCategory(category);
	};

	const toggleCategoryMerchantList = (MerchantList) => {
		setCategoryMerchantList(MerchantList);
	};

	const toggleShowListMap = (Body) => {
		props.onModalVisibility(true, "modal_loading");
		setTimeout(() => {
			setBodyVisible(Body);
			props.onModalVisibility(false, "");
		}, 1000);
	};

	useEffect(() => {
		if (window.ReactNativeWebView) {
			window.ReactNativeWebView.postMessage(
				JSON.stringify({
					type: "WEBAPP_UPDATE",
					title: "DISPLAY_BODY",
					data: BodyVisible,
				})
			);
		}
	}, [BodyVisible]);

	useEffect(() => {
		if (props.onIsMerchantToLocate.status) {
			toggleShowListMap("Map");
			setMarkerCoordinates(props.onIsMerchantToLocate.location);
			props.onMerchantToLocate(false);
		}
	}, [props.onIsMerchantToLocate.status]);

	useEffect(() => {
		if (RenderType !== undefined) {
			if (RenderType !== "application") {
				if (props.onUserLocation === undefined) {
					if (navigator.geolocation) {
						// Show loading modal before starting the request
						props.onModalVisibility(true, "modal_loading");
						userLocateRef.current = navigator.geolocation.watchPosition(
							function (position) {
								console.log("[ACCURACY]:", position.coords.accuracy);
								console.log("[LOCATION]:", position.coords.latitude, position.coords.longitude);

								// Hide loading modal once the position is received
								if (props.onWhatModal === "modal_loading") {
									props.onModalVisibility(false, "");
								}

								props.onSetUserLocation({
									lat: position.coords.latitude,
									lng: position.coords.longitude,
								});

								if (loadingRef.current) {
									clearTimeout(loadingRef.current);
									loadingRef.current = null;
								}

								loadingRef.current = setTimeout(() => {
									if (position.coords.accuracy > 100) {
										setUserMarkerVisibility(false);
										props.onAlertVisibility(
											true,
											"Can't find your exact location. Try restarting your network or refresh the page.",
											"error"
										);
										console.log("The GPS accuracy isn't good enough");
									} else {
										setUserMarkerVisibility(true);
									}

									clearTimeout(loadingRef.current);
									loadingRef.current = null;

									if (userLocateRef.current !== null) {
										navigator.geolocation.clearWatch(userLocateRef.current);
										userLocateRef.current = null;
									}
								}, 3000);
							},
							(error) => {
								// Hide loading modal if an error occurs
								props.onModalVisibility(false, "");

								// Show location error modal
								props.onModalVisibility(true, "modal_location");

								props.onSetAsCurrent("");
								switch (error.code) {
									case error.PERMISSION_DENIED:
										console.log("User denied the request for Geolocation.");
										break;
									case error.POSITION_UNAVAILABLE:
										console.log("Location information is unavailable.");
										break;
									case error.TIMEOUT:
										console.log("The request to get user location timed out.");
										break;
									case error.UNKNOWN_ERROR:
										console.log("An unknown error occurred.");
										break;
								}

								console.log("ERROR:", error.code, error);
							},
							{
								enableHighAccuracy: true,
								timeout: 15000, // Set timeout to 10 seconds
								maximumAge: 0, // Allow cached locations up to 30 seconds old
							}
						);
					}
				} else {
					setUserMarkerVisibility(true);
				}
			} else {
				console.log("WELCOME BITCH!");
			}
		} else {
			console.log("WELCOME BITCH!");
		}

		return () => {
			// Cleanup when component unmounts
			if (userLocateRef.current !== null) {
				navigator.geolocation.clearWatch(userLocateRef.current);
			}
			if (loadingRef.current) {
				clearTimeout(loadingRef.current);
			}
		};
	}, [props.onUserLocation, RenderType]);

	useEffect(() => {
		props.onModalVisibility(false, "");
		console.log("CATEGORY MERCHANT LIST :::::::> ", CategoryMerchantList);
	}, [CategoryMerchantList]);

	return (
		<div id='page_home'>
			{RenderType === "website" && (
				<Header
					onIfLoggedIn={props.onIfLoggedIn}
					onModalVisibility={props.onModalVisibility}
				/>
			)}
			{props.onIfLoggedIn && StatusChange !== undefined && (
				<BookingStatus
					onModalVisibility={props.onModalVisibility}
					onIfLoggedIn={props.onIfLoggedIn}
					onWhatRenderType={RenderType}
				/>
			)}

			{BodyVisible === "Main" ? (
				<MainBody
					onIfLoggedIn={props.onIfLoggedIn}
					onModalVisibility={props.onModalVisibility}
					onWhatActiveCategory={ActiveCategory}
					onUserPosition={props.onUserLocation}
					onCategoryMerchantList={CategoryMerchantList}
					onSetCatMerchantList={toggleCategoryMerchantList}
					onMerchantSelection={props.onMerchantSelection}
					onIsFilterEnabled={props.onIsFilterEnabled}
					onFilteredData={props.onFilteredData}
					onWhatModal={props.onWhatModal}
					StatusChange={StatusChange}
				/>
			) : (
				<MapBody
					onIfLoggedIn={props.onIfLoggedIn}
					onModalVisibility={props.onModalVisibility}
					onUserPosition={props.onUserLocation}
					onUserAppLocation={props.onUserAppLocation}
					onUserMarker={UserMarkerVisibility}
					onMerchantMarker={MarkerCoordinates}
					onMerchantSelection={props.onMerchantSelection}
					onWhatModal={props.onWhatModal}
					onCategoryMerchantList={CategoryMerchantList}
					onFilteredData={props.onFilteredData}
				/>
			)}
			{props.onIfLoggedIn !== undefined && (
				<BottomNavigation
					onIfLoggedIn={props.onIfLoggedIn}
					onSetWhatShowing={toggleShowListMap}
					onSetActiveCategory={toggleActiveCategory}
					onModalVisibility={props.onModalVisibility}
					onIsShowing={BodyVisible}
					onIfSelected={props.onIfSelected}
					onSetSelected={props.onSetSelected}
					onUserPosition={props.onUserLocation}
					onSetCatMerchantList={toggleCategoryMerchantList}
				/>
			)}
		</div>
	);
};

export default Home;
