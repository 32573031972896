import { useState, useEffect, useContext, useRef } from "react";
import { motion } from "framer-motion";
import CloseIcon from "@mui/icons-material/Close";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { Button, Tooltip, CircularProgress, Typography, Box } from "@mui/material";
import { format, isSameDay } from "date-fns";
import "../css/submodal.css";

import TryReserve from "../../../functions/TryReserve";
import DecryptData from "../../../functions/DecryptData";
import TryCancelReservation from "../../../functions/TryCancelReservation";
import ActivateCharger from "../../../functions/ActivateCharger";
import GetCurrentLocation from "../../../functions/GetCurrentLocation";
import UseReservation from "../../../functions/UseReservation";
import ValidateTimeslotReservation from "../../../functions/ValidateTimeslotReservation";

import subtractOneSecond from "../../../functions/SubtractOneSecond";

import { renderTheme } from "../../../App";

const variants = {
	open: { translateY: "0%" },
	closed: { translateY: "100%" },
};

const SubModalResConfirm = (props) => {
	const intervalIDRef = useRef(null);
	const [isOpen, setIsOpen] = useState(false);
	const [ItemDetails, setItemDetails] = useState();
	const [ReservationDetails, setReservationDetails] = useState();
	const [DisplayDate, setDisplayDate] = useState("-");
	const [DisplayStart, setDisplayStart] = useState("-");
	const [DisplayEnd, setDisplayEnd] = useState("-");
	const [ReservationID, setReservationID] = useState();
	const [SlotReservationType, setSlotReservationType] = useState();
	const [StepType, setStepType] = useState();
	const [IsReserved, setIsReserved] = useState(false);
	const [IsCancelled, setIsCancelled] = useState(false);
	const [IsActivated, setIsActivated] = useState(false);
	const [ChargerStatus, setChargerStatus] = useState("NOT_READY");
	const [EVConnectors, setEVConnectors] = useState();
	const [TimeLeft, setTimeLeft] = useState(0);
	const [TempGracePeriod, setTempGracePeriod] = useState("");
	const [GracePeriod, setGracePeriod] = useState("");
	const submodalRef = useRef(null);
	const intervalRef = useRef(null);

	const monthNames = [
		"January",
		"February",
		"March",
		"April",
		"May",
		"June",
		"July",
		"August",
		"September",
		"October",
		"November",
		"December",
	];

	// Function to format the date and time
	const formatGracePeriod = (gracePeriodDate) => {
		const currentDate = new Date();
		if (isSameDay(gracePeriodDate, currentDate)) {
			// If the date is the same, only show the time in am/pm format
			return format(gracePeriodDate, "hh:mm a");
		} else {
			// If the date is different, show the full date and time
			return format(gracePeriodDate, "yyyy-MM-dd hh:mm a");
		}
	};
	// RENDER THEME
	const { toggleStatusChange, setChargeStep, isMobile } = useContext(renderTheme);

	useEffect(() => {
		setIsOpen(true);
	}, []);

	useEffect(() => {
		// Start countdown when TimeLeft is set
		if (TimeLeft > 0) {
			const interval = setInterval(() => {
				setTimeLeft((prevTimeLeft) => {
					if (prevTimeLeft <= 1) {
						clearInterval(interval);
						return 0; // Stop countdown at 0
					}
					return prevTimeLeft - 1; // Decrement by 1 second
				});
			}, 1000); // Runs every second

			// Clean up the interval on component unmount or when TimeLeft changes
			return () => clearInterval(interval);
		}
	}, [TimeLeft]);

	useEffect(() => {
		if (IsActivated) {
			setIsCancelled(false);
			setIsReserved(false);
			props.onRefreshSlot(
				undefined,
				true,
				ItemDetails.slotName,
				ItemDetails.chargerID,
				ItemDetails.locationID,
				EVConnectors
			);
			props.onSubModalClose(false, undefined);
		}

		if (IsReserved) {
			setIsCancelled(false);
			setIsActivated(false);
			props.onRefreshSlot(
				undefined,
				true,
				ItemDetails.slotName,
				ItemDetails.chargerID,
				ItemDetails.locationID,
				EVConnectors
			);
		}

		if (IsCancelled) {
			setIsReserved(false);
			setIsActivated(false);
			props.onSubModalClose(false, undefined);
			props.onRefreshSlot(
				undefined,
				true,
				ItemDetails.slotName,
				ItemDetails.chargerID,
				ItemDetails.locationID,
				EVConnectors
			);
		}
	}, [IsReserved, IsCancelled, IsActivated]);

	useEffect(() => {
		console.log("[SLOT DATA]:", props.onWhatSlotData);
		if (props.onWhatSlotData !== undefined) {
			setEVConnectors(props.onWhatSlotData.EVConnectors ? props.onWhatSlotData.EVConnectors : undefined);
			setItemDetails(props.onWhatSlotData.itemDetails ? props.onWhatSlotData.itemDetails : undefined);
			// setSlotReservationType(props.onWhatSlotData.itemDetails.status);
		}

		if (props.onWhatReservationData !== undefined) {
			setReservationDetails(props.onWhatReservationData.reservationDetails);
			setReservationID(props.onWhatReservationData.reservationDetails.reservationID);
			setTempGracePeriod(props.onWhatReservationData.reservationDetails.grace_period);
		}
	}, [props.onWhatSlotData, props.onWhatReservationData]);

	useEffect(() => {
		console.log(ItemDetails);
		if (ItemDetails !== undefined) {
			const dateObject = new Date(ItemDetails.date);

			// Get day, month, and year
			const day = dateObject.getDate();
			const month = monthNames[dateObject.getMonth()];
			const year = dateObject.getFullYear();
			let displayDate = month + " " + day + ", " + year;

			let displayStart = TimeConvert(ItemDetails.start);
			let displayEnd = TimeConvert(ItemDetails.end);

			setDisplayDate(displayDate);
			setDisplayStart(displayStart);
			setDisplayEnd(displayEnd);

			let currentDate = GetCurrentDate();
			let currentTime = getCurrentTimeWithSeconds();
			let reqLocation = GetCurrentLocation();
			setSlotReservationType("LOADING");
			reqLocation.then((data_reqLocation) => {
				let savedToken = DecryptData("access_token");
				if (savedToken !== null) {
					let request = ActivateCharger(
						savedToken,
						ItemDetails.locationID,
						ItemDetails.chargerID,
						"inquire",
						data_reqLocation.latitude,
						data_reqLocation.longitude,
						currentTime,
						currentDate,
						ItemDetails.connectorID
					);
					request.then((res) => {
						console.log("[INQUIRE]:", res);
						if (res.data.remarks === "INSUFFICIENT_BALANCE") {
							setStepType();
							setSlotReservationType("INSUFFICIENT_BALANCE");
						}
						console.log("[ITEM DETAILS]:", ItemDetails);
						console.log("[REMARKS]:", res.data);

						if (ItemDetails && ItemDetails.status === "ONLINE" && ItemDetails.time_remaining) {
							// const timeInSeconds = convertToSeconds();
							setTimeLeft(ItemDetails.time_remaining);
						}
						if (ItemDetails.status === "ONLINE") {
							if (res.data.remarks === "CHARGER_AVAILABLE" || res.data.remarks === "TIMESLOT_CHARGING") {
								setSlotReservationType("ONLINE");
							}
							setTimeLeft(res.data.time_remaining);
							setStepType("RESERVATION");
						} else {
							if (ItemDetails.status === "RESERVED") {
								setTimeLeft(res.data.time_remaining);
								setSlotReservationType("RESERVED");
								setStepType("RESERVATION");
							}
						}
					});
				}
			});
		}
	}, [ItemDetails]);

	useEffect(() => {
		if (SlotReservationType !== undefined) {
			console.log(SlotReservationType);
			let currentDate = GetCurrentDate();
			let currentTime = getCurrentTimeWithSeconds();
			let request = ValidateTimeslotReservation(ItemDetails.timeslotID, currentTime, currentDate, ItemDetails.date);
			request.then((data) => {
				console.log("[RESERVATION TYPE CHECK]:", data);
				if (data.message === "INVALID_TO_ACTIVATE") {
					setChargerStatus("NOT_READY");
				} else {
					setChargerStatus("READY");
				}
			});
		}
	}, [SlotReservationType]);

	useEffect(() => {
		if (TempGracePeriod !== "") {
			const grace_period = formatGracePeriod(TempGracePeriod);
			setGracePeriod(grace_period);
		}
	}, [TempGracePeriod]);

	const TimeConvert = (timeString) => {
		// Extract hours, minutes, and seconds
		const [hours, minutes] = timeString.split(":");
		// Convert hours to 12-hour format
		const formattedHours = hours % 12 || 12;
		// Determine if it's AM or PM
		const period = hours < 12 ? "AM" : "PM";

		// Create the formatted time string without seconds
		const formattedTimeString = `${formattedHours}:${minutes} ${period}`;

		return formattedTimeString;
	};

	const getCurrentTimeWithSeconds = () => {
		const now = new Date();
		const hours = now.getHours().toString().padStart(2, "0");
		const minutes = now.getMinutes().toString().padStart(2, "0");
		const seconds = now.getSeconds().toString().padStart(2, "0");

		return `${hours}:${minutes}:${seconds}`;
	};

	const GetCurrentDate = () => {
		const dateObject = new Date();

		// Get date components
		const year = dateObject.getFullYear();
		const month = (dateObject.getMonth() + 1).toString().padStart(2, "0");
		const day = dateObject.getDate().toString().padStart(2, "0");

		// Formatted date
		const formattedDate = `${year}-${month}-${day}`;

		return formattedDate;
	};

	const toggleSubModalClose = () => {
		setIsOpen(false);
		setTimeout(() => {
			props.onSubModalClose(false, undefined);
		}, 250);
	};

	const toggleUseReservation = () => {
		let savedToken = DecryptData("access_token");

		if (savedToken !== null) {
			setTimeout(() => {
				let activate_request = UseReservation(savedToken, ReservationID);
				activate_request.then((act_req_data) => {
					if (act_req_data.result.status === "SUCCESS") {
						setChargeStep("WAITING");
						setIsActivated(true);
						toggleStatusChange("USER_CHARGING");
						props.onModalVisibility(true, "modal_charging_reservation_details");
						props.onSubModalClose(false, undefined);
						props.onAlertVisibility(true, "Charger activated! Connect the plug into your EV now.", "info");
					}
				});
			}, 250);
		}
	};

	const toggleReservationConfirm = () => {
		let savedToken = DecryptData("access_token");

		if (savedToken !== null) {
			if (!IsReserved && ItemDetails !== undefined) {
				let resRequest = TryReserve(savedToken, {
					evc_id: ItemDetails.chargerID,
					connector_id: ItemDetails.connectorID,
					timeslot_id: ItemDetails.timeslotID,
					timeslot_date: ItemDetails.date,
					next_timeslot_id: ItemDetails.nextTimeslotID,
					next_timeslot_date: ItemDetails.nextTimeslotDate,
					date: GetCurrentDate(),
					current_time: getCurrentTimeWithSeconds(),
					want_to_book: 0,
				});
				console.log(resRequest);
				resRequest.then((res) => {
					if (res.message === "DRIVER_ALREADY_BOOKED") {
						props.onSubModalClose(false, undefined);
						props.onAlertVisibility(
							true,
							"You have an existing reservation, cancel it first if you want to reserve other timeslot.",
							"warning"
						);
					} else if (res.message === "DO_YOU_WANT_TO_BOOK_THE_NEXT_TIMESLOT") {
						setSlotReservationType("DOUBLE_BOOKING");
					} else {
						setReservationID(res.message.reservations[0].reservation_id);
						toggleStatusChange("USER_RESERVATION");
						setSlotReservationType("RESERVED");
						setIsReserved(true);
						setTempGracePeriod(res.message.reservations[0].grace_period);
						// props.onSubModalClose(false, undefined);
						props.onAlertVisibility(true, "Timeslot reserved successfully!", "success");
					}
				});
			}
		}
	};

	const ToggleReservationCancel = () => {
		let savedToken = DecryptData("access_token");

		if (savedToken !== null) {
			let resRequest = TryCancelReservation(
				savedToken,
				ReservationID,
				ItemDetails.chargerID,
				ItemDetails.timeslotID,
				ItemDetails.connectorID
			);

			resRequest.then((res) => {
				if (res !== undefined) {
					if (res.status === 200) {
						setSlotReservationType("ONLINE");
						setStepType("RESERVATION");
						setIsCancelled(true);
						toggleStatusChange();
						props.onAlertVisibility(true, "Timeslot reservation cancelled successfully!", "error");
					}
				}
			});
		}
	};

	const toggleLiveTimeLeft = (time) => {
		setTimeLeft(time);
	};

	useEffect(() => {
		if (TimeLeft !== 0) {
			const { formattedTime } = subtractOneSecond(TimeLeft, intervalIDRef, toggleLiveTimeLeft);
		}
	}, [TimeLeft]);

	return (
		<div
			id='submodal_resconfirm'
			ref={submodalRef}>
			<motion.div
				className={
					SlotReservationType === "INSUFFICIENT_BALANCE"
						? "submodal-card card-resconfirm low-balance"
						: "submodal-card card-resconfirm"
				}
				initial={{ translateY: "100%" }}
				animate={isOpen ? "open" : "closed"}
				variants={variants}
				transition={{ duration: 0.3 }}>
				<div
					className={
						SlotReservationType === "RESERVED"
							? "submodal-header reserved"
							: SlotReservationType === "INSUFFICIENT_BALANCE"
							? "submodal-header low-balance"
							: SlotReservationType === "ONLINE"
							? "submodal-header online"
							: SlotReservationType === "LOADING"
							? "submodal-header loading"
							: "submodal-header default"
					}>
					<span className='header-blank'>&nbsp;</span>
					<span>
						{SlotReservationType === "RESERVED"
							? "Reserved Timeslot"
							: SlotReservationType === "INSUFFICIENT_BALANCE"
							? "Insufficient Balance"
							: SlotReservationType === "ONLINE"
							? "Timeslot Reservation"
							: "Retrieving your location... "}
					</span>
					<span className='header-close'>
						{isMobile ? (
							<ExpandMoreIcon
								style={{ fontSize: "1.5rem", fontWeight: "bold", alignItems: "center" }}
								className={
									SlotReservationType === "INSUFFICIENT_BALANCE"
										? "submodal-close-btn low-balance"
										: "submodal-close-btn"
								}
								onClick={toggleSubModalClose}
							/>
						) : (
							<CloseIcon
								style={{ fontSize: "1.5rem", fontWeight: "bold", alignItems: "center" }}
								className={
									SlotReservationType === "INSUFFICIENT_BALANCE"
										? "submodal-close-btn low-balance"
										: "submodal-close-btn"
								}
								onClick={toggleSubModalClose}
							/>
						)}
					</span>
				</div>
				{StepType !== "CANCELLATION" &&
					SlotReservationType !== "LOADING" &&
					SlotReservationType !== "INSUFFICIENT_BALANCE" && (
						<Box sx={{ display: "flex", justifyContent: "flex-end" }}>
							<Box
								sx={{
									// border: "solid 4px #1a6fff",
									// color: "#1a6fff",
									padding: "16px 8px 4px 8px",
									// borderRadius: "0 0 8px 0",
								}}>
								{GracePeriod !== "" && (
									<Typography
										variant='subtitle2'
										sx={{ textAlign: "end" }}>
										Reserved until: <span style={{ color: "red" }}>{GracePeriod}</span>
									</Typography>
								)}

								<Typography variant='subtitle2'>
									Available Time Left(hr/s):{" "}
									{ChargerStatus === "READY" ? (
										<span style={{ color: "green" }}>{TimeLeft}</span>
									) : (
										<span style={{ color: "#ffb000" }}>PENDING</span>
									)}
								</Typography>
							</Box>
						</Box>
					)}

				<div className='submodal-content'>
					{StepType === "RESERVATION" && SlotReservationType !== "INSUFFICIENT_BALANCE" && (
						<div className='submodal-reservation-details'>
							<span>
								<span className='fw-bold'>Station:</span> {ItemDetails !== undefined ? ItemDetails.slotName : "-"}
							</span>
							<span>
								<span className='fw-bold'>{SlotReservationType === "RESERVED" ? "Reserved" : "Reservation"} Date:</span>{" "}
								{DisplayDate}
							</span>
							<span style={{ whiteSpace: "nowrap" }}>
								<span className='fw-bold'>{SlotReservationType === "RESERVED" ? "Reserved" : "Reservation"} Time:</span>{" "}
								{DisplayStart} - {DisplayEnd}
							</span>
							<span style={{ whiteSpace: "nowrap" }}>
								<span className='fw-bold'>Plug Type:</span>{" "}
								{ItemDetails !== undefined ? ItemDetails.connectorType.replace("_", " ") : "-"}
							</span>
						</div>
					)}
					{SlotReservationType === "LOADING" && (
						<div
							style={{
								padding: "8px",
								display: "flex",
								justifyContent: "center",
								alignItems: "center",
								flexDirection: "column",
							}}>
							<span style={{ marginBottom: "8px", textAlign: "center", whiteSpace: "wrap" }}>
								If it takes longer than usual, please consider refreshing the page.
							</span>
							<CircularProgress />
						</div>
					)}

					{SlotReservationType === "INSUFFICIENT_BALANCE" && (
						<div className='submodal-reserve-confirm text-center'>
							<span>
								Your account balance is insufficient to make a reservation for charging. Would you like to make a
								top-up?
							</span>
						</div>
					)}

					{/* {StepType === "RESERVATION" && SlotReservationType === "ONLINE" && (
						<div className='submodal-reserve-confirm text-center'>
							<span>Are you sure you want to reserve this timeslot?</span>
						</div>
					)} */}
					{StepType === "CANCELLATION" && (
						<>
							<div className='submodal-reserve-confirm text-center'>
								<span>Are you sure you want to cancel your reservation?</span>
							</div>

							<div className='submodal-btn-group-to-cancel'>
								<Button
									variant='text'
									sx={{ fontSize: ".75rem" }}
									onClick={() => setStepType("RESERVATION")}>
									No
								</Button>
								<Button
									color='error'
									variant='contained'
									sx={{ marginBottom: 1, width: "max-content", display: "flex", alignItems: "center" }}
									onClick={ToggleReservationCancel}>
									Yes
								</Button>
							</div>
						</>
					)}
					{StepType === "RESERVATION" && SlotReservationType === "ONLINE" && (
						<div className='submodal-btn-group'>
							<Button
								sx={{ width: "80%" }}
								variant='contained'
								onClick={toggleReservationConfirm}>
								Reserve this Timeslot
							</Button>
						</div>
					)}
					{StepType === "RESERVATION" && SlotReservationType === "RESERVED" && (
						<div className='submodal-btn-group-reserved'>
							<Tooltip
								title={ChargerStatus === "NOT_READY" && "Reserved time has not been met yet."}
								placement='top'
								enterTouchDelay={0}>
								<span>
									<Button
										disabled={ChargerStatus === "NOT_READY" ? true : false}
										variant='contained'
										sx={{ width: "max-content", display: "flex", alignItems: "center" }}
										onClick={toggleUseReservation}>
										Activate Charger
									</Button>
								</span>
							</Tooltip>
							<Button
								variant='text'
								color='error'
								sx={{ fontSize: ".75rem" }}
								onClick={() => setStepType("CANCELLATION")}>
								Cancel Reservation
							</Button>
						</div>
					)}
					{SlotReservationType === "INSUFFICIENT_BALANCE" && (
						<div className='submodal-btn-group-insufficient'>
							<Button
								variant='text'
								color='error'
								onClick={toggleSubModalClose}>
								No
							</Button>
							<Button
								color='success'
								variant='contained'
								onClick={() => props.onSubModalClose(true, "submodal_payment_method")}>
								Go to Top up
							</Button>
						</div>
					)}
				</div>
			</motion.div>
		</div>
	);
};

export default SubModalResConfirm;
