import { useEffect, useState, useContext, useRef } from "react";
import _ from "lodash";
import { motion } from "framer-motion";
import "../css/modal.css";
import CloseIcon from "@mui/icons-material/Close";
import { Button, Typography, Box, Alert, List, ListItem } from "@mui/material";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import CircularProgress from "@mui/material/CircularProgress";
import FormHelperText from "@mui/material/FormHelperText";

import TrySignUp from "../../../functions/TrySignUp";
import Reg_VerifyOTP from "../../../functions/Reg_VerifyOTP";
import ValidateRegistrationData from "../../../functions/ValidateRegistrationData";
import EncryptData from "../../../functions/EncryptData";

import OTPField from "./subcomponents/OTPField";

import { renderTheme } from "../../../App";

const variants = {
	open: { opacity: 1, y: "-10%" },
	closed: { opacity: 0, y: "-100%" },
};

const ModalSignup = (props) => {
	const [OnWhatStep, setOnWhatStep] = useState("step1");
	const [ErrorVerify, setErrorVerify] = useState("");
	const [UserID, setUserID] = useState("");
	const [OTP, setOTP] = useState("");
	const [isOpen, setIsOpen] = useState(false);

	const [AreFieldsFilled, setAreFieldsFilled] = useState(false);
	const [Firstname, setFirstname] = useState("");
	const [Lastname, setLastname] = useState("");
	const [Address, setAddress] = useState("");
	const [Username, setUsername] = useState("");
	const [Email, setEmail] = useState("");
	const [MobileNumber, setMobileNumber] = useState("");
	const [PlateNumber, setPlateNumber] = useState("");
	const [VehicleBrand, setVehicleBrand] = useState("");
	const [VehicleModel, setVehicleModel] = useState("");

	const [NumReEval, setNumReEval] = useState(false);
	const [EmailReEval, setEmailReEval] = useState(false);
	const [UsernameReEval, setUsernameReEval] = useState(false);
	const [PlateNumberReEval, setPlateNumberReEval] = useState(false);

	const [RegistrationError, setRegistrationError] = useState("clear");
	const [FirstNameError, setFirstNameError] = useState("");
	const [LastNameError, setLastNameError] = useState("");
	const [AddressError, setAddressError] = useState("");
	const [MobileNumberError, setMobileNumberError] = useState("");
	const [EmailError, setEmailError] = useState("");
	const [UsernameError, setUsernameError] = useState("");
	const [PlateNumberError, setPlateNumberError] = useState("");
	const [IsLoading, setIsLoading] = useState("");

	const EmailAddress_TimeoutRef = useRef(null);
	const MobileNumber_TimeoutRef = useRef(null);
	const Username_TimeoutRef = useRef(null);
	const PlateNumber_TimeoutRef = useRef(null);

	const [CountTimer, setCountTimer] = useState({
		minutes: 0,
		seconds: 0,
	});
	const [isRunning, setIsRunning] = useState(false);

	// RENDER THEME
	const { RenderType, IsTCPPChecked, setIsTCPPChecked } = useContext(renderTheme);

	useEffect(() => {
		let timer;

		if (isRunning) {
			timer = setInterval(() => {
				if (CountTimer.minutes === 0 && CountTimer.seconds === 0) {
					clearInterval(timer);
					setIsRunning(false);
					// Handle timer completion (e.g., show a message, trigger an action)
				} else {
					setCountTimer((prevTime) => {
						if (prevTime.seconds === 0) {
							return {
								minutes: prevTime.minutes - 1,
								seconds: 59,
							};
						} else {
							return {
								minutes: prevTime.minutes,
								seconds: prevTime.seconds - 1,
							};
						}
					});
				}
			}, 1000);
		}

		// Clean up the interval on component unmount or when isRunning becomes false
		return () => clearInterval(timer);
	}, [CountTimer, isRunning]);

	const formatTime = (value) => (value < 10 ? `0${value}` : value);

	// const toggleResend = (event, type) => {
	// 	setIsLoading(type);
	// 	setErrorVerify("");
	// 	// props.onModalClose(true, "modal_loading");
	// 	let request = FP_SendOTP(BasicToken, Email);
	// 	request.then((res) => {
	// 		if (res.status === 200) {
	// 			setUserID(res.data[0].USER_ID);
	// 			setIsLoading("");
	// 			setResendDisabled(true);
	// 			setCountTimer({
	// 				minutes: 2,
	// 				seconds: 0,
	// 			});
	// 			setIsRunning(true);
	// 		}
	// 	});
	// };

	const toggleModalClose = () => {
		setIsOpen(false);
		setTimeout(() => {
			props.onModalClose(false, "");
		}, 250);
	};

	const validateName = (value) => {
		// const pattern = /^[a-zA-Z]{2,40}( [a-zA-Z]{2,40})+$/;
		// console.log('zxc', pattern.test(value))
		if (_.isEmpty(value.trim())) {
			return "empty_string";
		} else if (value.trim().length < 2) {
			return "below_min_string";
		}
		// else if (!pattern.test(value)) {
		// 	return ErrorMessage.ERROR_SPECIAL_CHAR_NUM;
		// }
		return "";
	};

	const validateEmail = (value) => {
		if (EmailAddress_TimeoutRef.current !== null) {
			clearTimeout(EmailAddress_TimeoutRef.current);
			EmailAddress_TimeoutRef.current = null;
		}

		// Return a promise to resolve after the timeout
		return new Promise((resolve) => {
			let reg = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w\w+)+$/;

			EmailAddress_TimeoutRef.current = setTimeout(async () => {
				if (_.isEmpty(value.trim())) {
					return resolve("empty_string");
				} else if (reg.test(value) === false) {
					return resolve("invalid_email");
				}

				// Validate the data asynchronously
				try {
					let result = await ValidateData("email_address", value);

					// Handle the result
					if (result.status !== 200) {
						if (result.message === "EMAIL_DOES_EXISTS") {
							return resolve("email_address_exists");
						}
					}
				} catch (error) {
					console.error("Error during validation:", error);
				}

				// Everything is valid
				return resolve("");
			}, 250);
		});
	};

	const validateNumber = (value) => {
		if (MobileNumber_TimeoutRef.current !== null) {
			clearTimeout(MobileNumber_TimeoutRef.current);
			MobileNumber_TimeoutRef.current = null;
		}

		// Return a promise to resolve after the timeout
		return new Promise((resolve) => {
			// Updated regex pattern to match mobile numbers (Philippines)
			let reg = /^(09|\+639)\d{9}$/;

			MobileNumber_TimeoutRef.current = setTimeout(async () => {
				// Check for empty string
				if (_.isEmpty(value.trim())) {
					return resolve("empty_string");
				}

				// Check for invalid number format (regex validation)
				if (!reg.test(value)) {
					return resolve("invalid_number");
				}

				// Asynchronous validation (e.g., checking if the number already exists)
				try {
					let result = await ValidateData("contact_number", value);

					// Handle the result
					if (result.status !== 200) {
						if (result.message === "MOBILE_NUMBER_DOES_EXISTS") {
							return resolve("mobile_number_exists");
						}
					}
				} catch (error) {
					console.error("Error during validation:", error);
				}

				// Everything is valid
				return resolve("");
			}, 250);
		});
	};

	const validateUsername = (value) => {
		if (Username_TimeoutRef.current !== null) {
			clearTimeout(Username_TimeoutRef.current);
			Username_TimeoutRef.current = null;
		}

		// Return a promise to resolve after the timeout
		return new Promise((resolve) => {
			Username_TimeoutRef.current = setTimeout(async () => {
				if (_.isEmpty(value.trim())) {
					return resolve("empty_string");
				}

				if (value.length < 3) {
					return resolve("below_min_string");
				}
				// Validate the data asynchronously
				try {
					let result = await ValidateData("username", value);

					// Handle the result
					if (result.status !== 200) {
						if (result.message === "USERNAME_DOES_EXISTS") {
							return resolve("username_exists");
						}
					}
				} catch (error) {
					console.error("Error during validation:", error);
				}

				// Everything is valid
				return resolve("");
			}, 250);
		});
	};

	const validatePlateNumber = (value) => {
		if (PlateNumber_TimeoutRef.current !== null) {
			clearTimeout(PlateNumber_TimeoutRef.current);
			PlateNumber_TimeoutRef.current = null;
		}

		// Return a promise to resolve after the timeout
		return new Promise((resolve) => {
			PlateNumber_TimeoutRef.current = setTimeout(async () => {
				if (_.isEmpty(value.trim())) {
					return resolve("empty_string");
				}
				console.log(value.trim());
				if (value.trim().length < 7) {
					return resolve("below_min_string");
				}
				// Validate the data asynchronously
				try {
					let result = await ValidateData("vehicle_plate_number", value);

					// Handle the result
					if (result.status !== 200) {
						console.log(result);
						if (result.message === "VEHICLE_PLATE_NUMBER_DOES_EXISTS") {
							return resolve("plate_number_exists");
						}
					}
				} catch (error) {
					console.error("Error during validation:", error);
				}

				// Everything is valid
				return resolve("");
			}, 250);
		});
	};

	useEffect(() => {
		// Create an async function to handle the async validation
		const validate = async () => {
			let result = await validateNumber(MobileNumber);
			console.log(result);
			setMobileNumberError(result);
		};
		if (NumReEval) {
			validate(); // Call the async function
			setNumReEval(false);
		}
	}, [MobileNumber, NumReEval]);

	useEffect(() => {
		// Create an async function to handle the async validation
		const validate = async () => {
			let result = await validateEmail(Email);
			console.log(result);
			setEmailError(result);
		};

		if (EmailReEval) {
			validate(); // Call the async function
			setEmailReEval(false);
		}
	}, [Email, EmailReEval]);

	useEffect(() => {
		// Create an async function to handle the async validation
		const validate = async () => {
			let result = await validateUsername(Username);
			console.log(result);
			setUsernameError(result);
		};

		if (UsernameReEval) {
			validate(); // Call the async function
			setUsernameReEval(false);
		}
	}, [Username, UsernameReEval]);

	useEffect(() => {
		// Create an async function to handle the async validation
		const validate = async () => {
			let result = await validatePlateNumber(PlateNumber);
			console.log(result);
			setPlateNumberError(result);
		};

		if (PlateNumberReEval) {
			validate(); // Call the async function
			setPlateNumberReEval(false);
		}
	}, [PlateNumber, PlateNumberReEval]);

	const ValidateData = async (type, value) => {
		let request = await ValidateRegistrationData(type, value);
		return request; // Resolve the promise with the result
	};

	const toggleSignUp = (event, type) => {
		setIsLoading(type);

		if (IsTCPPChecked) {
			let request = TrySignUp(
				Firstname,
				Lastname,
				Address,
				MobileNumber,
				Email,
				Username,
				PlateNumber,
				VehicleBrand,
				VehicleModel
			);
			request.then((res) => {
				if (res.status === 200) {
					setIsLoading("");
					setUserID(res.data.user_id);
					setOnWhatStep("step2");
				} else {
					setIsLoading("");
				}
			});
		} else {
			if (!IsTCPPChecked) {
				props.onAlertVisibility(true, "Please accept Terms and Conditions and Privacy Policy", "error");
			}
			setIsLoading("");
		}

		// props.onModalClose(true, "modal_loading");
	};

	const toggleVerifyOTP = (event, type) => {
		setIsLoading(type);
		let request = Reg_VerifyOTP(UserID, OTP);
		request.then((res) => {
			if (res.status === 200) {
				setIsLoading("");
				setOnWhatStep("step3");
			} else {
				setIsLoading("");
			}
		});
	};

	useEffect(() => {
		const errors = {
			FN: FirstNameError,
			LN: LastNameError,
			Add: AddressError,
			MNum: MobileNumberError !== "tmp" ? MobileNumberError : "",
			UN: UsernameError !== "tmp" ? UsernameError : "",
			PN: PlateNumberError !== "tmp" ? PlateNumberError : "",
			Email: EmailError !== "tmp" ? EmailError : "",
		};

		const filteredErrors = Object.keys(errors).reduce((acc, key) => {
			if (errors[key] !== "") {
				acc[key] = errors[key];
			}
			return acc;
		}, {});

		// Check if the filteredErrors object is empty
		if (Object.keys(filteredErrors).length > 0) {
			setRegistrationError(filteredErrors);
		} else {
			setRegistrationError(null); // or set an empty object, depending on your use case
		}

		console.log("FIRST_NAME ERROR:", FirstNameError);
		console.log("LAST_NAME ERROR:", LastNameError);
		console.log("ADDRESS ERROR:", AddressError);
		console.log("MOBILE_NUMBER ERROR:", MobileNumberError);
		console.log("EMAIL ERROR:", EmailError);
		console.log("USERNAME ERROR:", UsernameError);
		console.log("PLATE NUMBER ERROR:", PlateNumberError);
	}, [FirstNameError, LastNameError, AddressError, MobileNumberError, EmailError, UsernameError, PlateNumberError]);

	useEffect(() => {
		console.log("[RegERROR]:", RegistrationError);
	}, [RegistrationError]);

	useEffect(() => {
		setIsOpen(true);
	}, []);

	const handleFirstnameInput = (e) => {
		setFirstname(e.target.value);
	};

	const handleLastnameInput = (e) => {
		setLastname(e.target.value);
	};
	const handleAddressInput = (e) => {
		setAddress(e.target.value);
	};
	const handleEmailInput = (e) => {
		setEmailReEval(true);
		setEmailError("tmp");
		setEmail(e.target.value);
	};
	const handleMobileNumberInput = (e) => {
		setNumReEval(true);
		setMobileNumberError("tmp");
		setMobileNumber(e.target.value);
	};
	const handlePlateNumberInput = (e) => {
		// Allow only uppercase letters and numbers
		const filteredValue = e.target.value.replace(/[^A-Z0-9]/gi, "").toUpperCase();

		setPlateNumberReEval(true);
		setPlateNumberError("tmp");
		setPlateNumber(filteredValue);
	};
	const handleVehicleBrandInput = (e) => {
		setVehicleBrand(e.target.value);
	};
	const handleVehicleModelInput = (e) => {
		setVehicleModel(e.target.value);
	};
	const handleUsernameInput = (e) => {
		setUsernameReEval(true);
		setUsernameError("tmp");
		setUsername(e.target.value);
	};

	return (
		<div id='modal_signup'>
			<motion.div
				className={OnWhatStep === "step1" ? "modal-card card-signup" : "modal-card card-otp"}
				// initial={{ opacity: 0, y: "-100%" }}
				// animate={{ x: 100 }}
				animate={isOpen ? "open" : "closed"}
				variants={variants}
				transition={{ duration: 0.3 }}>
				<div className='modal-header'>
					<span className='header-blank'>&nbsp;</span>
					<span> {OnWhatStep === "step1" ? "Signup" : OnWhatStep === "step2" ? "Verify OTP" : "Signup Complete"}</span>
					<span className='header-close'>
						<CloseIcon
							className='modal-close-btn'
							onClick={() => {
								setIsTCPPChecked(false);
								toggleModalClose();
							}}
						/>
					</span>
				</div>
				{OnWhatStep === "step1" ? (
					<div className='modal-content content-signup'>

						<div className='signup-form'>
							<div className='form-part'>
								<Typography
									variant='subtitle2'
									sx={{ alignSelf: "flex-start" }}>
									<span style={{ color: "red" }}>*&nbsp;</span>-&nbsp;Must be unique
								</Typography>
								<Typography
									variant='h6'
									sx={{ alignSelf: "flex-start" }}>
									Account Holder Details
								</Typography>
								<div className='form-input'>
									<label htmlFor='form_txt_firstname'>First Name</label>
									<input
										type='text'
										className={`form-control ${RegistrationError?.FN && "is-invalid"}`}
										value={Firstname}
										onChange={handleFirstnameInput}
										onBlur={() => {
											let result = validateName(Firstname);
											setFirstNameError(result);
										}}
										id='form_txt_firstname'
									/>
									{RegistrationError?.FN && (
										<Typography
											variant='caption'
											sx={{ marginLeft: "8px", color: "red" }}>
											{RegistrationError.FN === "empty_string"
												? "First name can't be left blank."
												: RegistrationError.FN === "below_min_string" && "Minimum of 2 characters is required"}
										</Typography>
									)}
								</div>
								<div className='form-input'>
									<label htmlFor='form_txt_lastname'>Last Name</label>
									<input
										type='text'
										className={`form-control ${RegistrationError?.LN && "is-invalid"}`}
										value={Lastname}
										onChange={handleLastnameInput}
										onBlur={() => {
											let result = validateName(Lastname);
											setLastNameError(result);
										}}
										id='form_txt_lastname'
									/>
									{RegistrationError?.LN && (
										<Typography
											variant='caption'
											sx={{ marginLeft: "8px", color: "red" }}>
											{RegistrationError.LN === "empty_string"
												? "Last name can't be left blank."
												: RegistrationError.LN === "below_min_string" && "Minimum of 2 characters is required"}
										</Typography>
									)}
								</div>
								<div className='form-input'>
									<label htmlFor='form_txt_address'>Address</label>
									<input
										type='text'
										className={`form-control ${RegistrationError?.Add && "is-invalid"}`}
										value={Address}
										onChange={handleAddressInput}
										onBlur={() => {
											let result = validateName(Address);
											setAddressError(result);
										}}
										id='form_txt_address'
									/>
									{RegistrationError?.Add && (
										<Typography
											variant='caption'
											sx={{ marginLeft: "8px", color: "red" }}>
											{RegistrationError.Add === "empty_string"
												? "Address can't be left blank."
												: RegistrationError.Add === "below_min_string" && "Minimum of 2 characters is required"}
										</Typography>
									)}
								</div>

								<div className='form-input'>
									<label htmlFor='form_txt_mobile_number'>
										Mobile Number&nbsp;<span style={{ color: "red" }}>*</span>
									</label>
									<input
										type='tel'
										className={`form-control ${
											RegistrationError?.MNum
												? "is-invalid"
												: MobileNumberError === "" && MobileNumber !== ""
												? "is-valid"
												: ""
										}`}
										placeholder='eg. +639/09#########'
										value={MobileNumber}
										onBlur={() => {
											setNumReEval(true);
										}}
										onFocus={() => {
											setMobileNumberError("tmp");
											setTimeout(() => {
												setNumReEval(true);
											}, 1000);
										}}
										onChange={handleMobileNumberInput}
										id='form_txt_mobile_number'
									/>
									{RegistrationError?.MNum && (
										<Typography
											variant='caption'
											sx={{ marginLeft: "8px", color: "red" }}>
											{RegistrationError.MNum === "empty_string"
												? "Mobile number can't be left blank."
												: RegistrationError.MNum === "below_min_number"
												? "Mobile Number must consist of 11 digits"
												: RegistrationError.MNum === "invalid_number"
												? "Please set a valid Mobile Number"
												: "Mobile Number already in use."}
										</Typography>
									)}
								</div>
								<div className='form-input'>
									<label htmlFor='form_txt_email'>
										Email&nbsp;<span style={{ color: "red" }}>*</span>
									</label>
									<input
										type='email'
										className={`form-control ${
											RegistrationError?.Email ? "is-invalid" : EmailError === "" && Email !== "" ? "is-valid" : ""
										}`}
										value={Email}
										onChange={handleEmailInput}
										onBlur={() => {
											setEmailReEval(true);
										}}
										onFocus={() => {
											setEmailError("tmp");
											setTimeout(() => {
												setEmailReEval(true);
											}, 1000);
										}}
										id='form_txt_email'
									/>
									{RegistrationError?.Email && (
										<Typography
											variant='caption'
											sx={{ marginLeft: "8px", color: "red" }}>
											{RegistrationError.Email === "empty_string"
												? "Email Address can't be left blank."
												: RegistrationError.Email === "invalid_email"
												? "Please set a valid Email Address.(format: user@example.com)"
												: "Email Address already in use."}
										</Typography>
									)}
								</div>
								<div className='form-input'>
									<label htmlFor='form_txt_username'>
										Username&nbsp;<span style={{ color: "red" }}>*</span>
									</label>
									<input
										type='text'
										className={`form-control ${
											RegistrationError?.UN ? "is-invalid" : UsernameError === "" && Username !== "" ? "is-valid" : ""
										}`}
										value={Username}
										onChange={handleUsernameInput}
										onBlur={() => {
											setUsernameReEval(true);
										}}
										onFocus={() => {
											setUsernameError("tmp");
											setTimeout(() => {
												setUsernameReEval(true);
											}, 1000);
										}}
										id='form_txt_username'
									/>
									{RegistrationError?.UN && (
										<Typography
											variant='caption'
											sx={{ marginLeft: "8px", color: "red" }}>
											{RegistrationError.UN === "empty_string"
												? "Username can't be left blank."
												: RegistrationError.UN === "below_min_string"
												? "Minimum of 3 characters is required"
												: "Username already in use."}
										</Typography>
									)}
								</div>
							</div>

							<div className='form-part'>
								<Typography
									variant='h6'
									sx={{ alignSelf: "flex-start" }}>
									Vehicle Details
								</Typography>
								<div className='form-input'>
									<label htmlFor='form_txt_plate_number'>
										Plate Number&nbsp;<span style={{ color: "red" }}>*</span>
									</label>
									<input
										type='text'
										placeholder='ABZ1234'
										className={`form-control ${
											RegistrationError?.PN
												? "is-invalid"
												: PlateNumberError === "" && PlateNumber !== ""
												? "is-valid"
												: ""
										}`}
										value={PlateNumber}
										onChange={handlePlateNumberInput}
										onBlur={() => {
											setPlateNumberReEval(true);
										}}
										onFocus={() => {
											setPlateNumberError("tmp");
											setTimeout(() => {
												setPlateNumberReEval(true);
											}, 1000);
										}}
										id='form_txt_plate_number'
									/>
									{RegistrationError?.PN && (
										<Typography
											variant='caption'
											sx={{ marginLeft: "8px", color: "red" }}>
											{RegistrationError.PN === "empty_string"
												? "Plate Number can't be left blank."
												: RegistrationError.PN === "below_min_string"
												? "Minimum of 7 characters is required"
												: "Plate Number already exists."}
										</Typography>
									)}
								</div>
								<div className='form-input'>
									<label htmlFor='form_txt_vehicle_brand'>Brand</label>
									<input
										type='text'
										placeholder='e.g. BYD, Tesla, Ford'
										className='form-control'
										value={VehicleBrand}
										onChange={handleVehicleBrandInput}
										id='form_txt_vehicle_brand'
									/>
								</div>
								<div className='form-input'>
									<label htmlFor='form_txt_vehicle_model'>Model</label>
									<input
										type='text'
										placeholder='e.g. Sealion 6 DM-i, Y, 2024 Mustang Mach-e'
										className='form-control'
										value={VehicleModel}
										onChange={handleVehicleModelInput}
										id='form_txt_vehicle_model'
									/>
								</div>
								<div
									style={{ display: "flex", flexDirection: "column", textAlign: "center" }}
									className='mt-5'>
									<FormControlLabel
										sx={{ fontSize: "8px" }}
										control={<Checkbox checked={IsTCPPChecked} />}
										onChange={() => {
											let wasChecked = false;
											if (IsTCPPChecked) {
												wasChecked = true;
												setIsTCPPChecked(false);
											} else {
												wasChecked = false;
											}
											if (wasChecked === false) {
												props.onSubModalVisibility(true, "submodal_terms_condition_privacy_policy");
											} else {
												props.onSubModalVisibility(false, undefined);
											}
										}}
										label='Read Terms and Conditions / Privacy Policy'
									/>
									<button
										disabled={
											RegistrationError !== null ||
											IsLoading ||
											Firstname === "" ||
											Lastname === "" ||
											Address === "" ||
											MobileNumber === "" ||
											Email === "" ||
											Username === "" ||
											PlateNumber === "" ||
											VehicleBrand === "" ||
											VehicleModel === ""
										}
										className='my-2 btn btn-primary'
										type='button'
										onClick={(event) => toggleSignUp(event, "signup1")}>
										{IsLoading === "signup1" ? (
											<CircularProgress
												color='inherit'
												size={16}
											/>
										) : (
											"Sign Up"
										)}
									</button>
								</div>
								<div
									className='addon-btn-group'
									style={{
										display: "flex",
										flexDirection: "row",
										alignItems: "center",
									}}>
									<Typography
										component={"caption"}
										sx={{ whiteSpace: "nowrap" }}>
										Already have an account?
									</Typography>
									<Button
										style={{ textTransform: "none", color: "#ff8f00" }}
										onClick={() => {
											setIsTCPPChecked(false);
											props.onModalClose(true, "modal_login");
										}}>
										Log In
									</Button>
								</div>
							</div>
						</div>
					</div>
				) : OnWhatStep === "step2" ? (
					<div className='modal-content content-signup-otp'>
						<OTPField
							otp={OTP}
							setOtp={setOTP}
						/>
						{ErrorVerify && (
							<FormHelperText sx={{ color: "red", marginTop: "0.5rem" }}>
								{ErrorVerify === "expired"
									? "OTP Expired."
									: ErrorVerify === "incorrect"
									? "Incorrect OTP."
									: "Maximum of 3 attempts has been reached. Try resending OTP."}
							</FormHelperText>
						)}
						<div className='mt-2'>
							<button
								className='my-2 btn btn-primary'
								type='button'
								onClick={(event) => toggleVerifyOTP((event, "signup2"))}>
								{IsLoading === "signup2" ? (
									<CircularProgress
										color='inherit'
										size={16}
									/>
								) : (
									"Verify OTP"
								)}
							</button>
						</div>
						<div style={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
							<Button
								style={{ textTransform: "none", color: "#ff8f00" }}
								// onClick={(event) => toggleResend(event, "resend")}
								disabled={isRunning}>
								{IsLoading === "resend" ? (
									<CircularProgress
										color='inherit'
										size={16}
									/>
								) : isRunning ? (
									`Resend (${formatTime(CountTimer.minutes)}:${formatTime(CountTimer.seconds)})`
								) : (
									"Resend"
								)}
							</Button>
						</div>
					</div>
				) : (
					<div className='modal-content content-signup-complete'>
						<Typography variant='subtitle1'>Account created and is now active. Try logging in your account.</Typography>
						<Button
							className='mt-3'
							onClick={() => props.onModalClose(true, "modal_login")}>
							Go To Login
						</Button>
					</div>
				)}
			</motion.div>
		</div>
	);
};

export default ModalSignup;
