import { Typography } from "@mui/material";
import LoadingGIF from "../../../assets/ParkNcharge_Loading.gif";

const ModalLoading = (props) => {
	return (
		<div id='modal_loading'>
			<Typography
				sx={{ color: "white" }}
				variant='subtitle2'>
				Fetching Data...
			</Typography>
			<div className='modal-card card-loading'>
				<img
					src={LoadingGIF}
					id='pnc_loading'
				/>
			</div>
		</div>
	);
};

export default ModalLoading;
