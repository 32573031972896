import RefreshToken from './RefreshToken';
import DecryptData from './DecryptData';

const GetTransactionHistory = async (accessToken, rowCount, page) => {
    let myHeaders = new Headers();
    myHeaders.append("Authorization", "Bearer " + accessToken);

    const requestOptions = {
        method: "GET",
        headers: myHeaders,
        redirect: "follow"
    };

    let response;

    try {
        // Initial request
        response = await fetch(
            `${process.env.REACT_APP_API_ADDRESS}/topup/api/v1/transactions?limit=${rowCount}&offset=${page}`,
            requestOptions
        );

        // Check if access token is expired (401 status)
        if (response.status === 401) {
            console.log("Access token expired, attempting to refresh...");

            // Decrypt and retrieve refresh token from localStorage
            const saved_refreshToken = DecryptData('refresh_token');

            if (saved_refreshToken) {
                // Try to refresh the token
                const refreshResponse = await RefreshToken(saved_refreshToken);

                if (refreshResponse.status === 200) {
                    // Successfully refreshed the token, update the access token and retry the request
                    const newAccessToken = refreshResponse.data.access_token;
                    console.log("New access token: ", newAccessToken);

                    // Update headers with the new token
                    myHeaders = new Headers();
                    myHeaders.append("Authorization", "Bearer " + newAccessToken);
                    requestOptions.headers = myHeaders;

                    // Retry the request with the new token
                    response = await fetch(
                        `${process.env.REACT_APP_API_ADDRESS}/topup/api/v1/transactions?limit=${rowCount}&offset=${page}`,
                        requestOptions
                    );

                    if (!response.ok) {
                        console.log(`Retry failed with status: ${response.status}`);
                        return null;
                    }
                } else {
                    console.log("Failed to refresh token. Logging out...");
                    localStorage.clear();
                    return null;
                }
            }

        } else if (!response.ok) {
            console.log(`Server error: ${response.status}`);
            return null;
        }

        return await response.json();
    } catch (error) {
        console.log("Network error or other unexpected issue:", error);
        return null;
    }
};

export default GetTransactionHistory;
