const ValidateTimeslotReservation = async (timeslotID, currentTime, currentDate, timeslotDate) => {
    const myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("Authorization", "Basic " + process.env.REACT_APP_API_KEY);

    const raw = JSON.stringify({
        "timeslot_id": timeslotID,
        "current_time": currentTime,
        "current_date": currentDate,
        "timeslot_date": timeslotDate
    });

    const requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow"
    };

    let response;

    try {
        response = await fetch(
            `${process.env.REACT_APP_API_ADDRESS}/booking_reservation/api/v1/check_current_time_and_date`,
            requestOptions
        );

        return response.json()
    } catch (error) {
        console.log("Network error or other unexpected issue:", error);
    }
}

export default ValidateTimeslotReservation;