import RefreshToken from './RefreshToken';
import DecryptData from './DecryptData';

const DelAsFav = async (AccessToken, MerchantID) => {
    var myHeaders = new Headers();
    myHeaders.append("Accept", "application/json");
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("Authorization", "Bearer " + AccessToken);

    var graphql = JSON.stringify({
        query: `mutation RemoveFromFavoriteLocation {\r\n    removeFromFavoriteLocation(cpo_location_id: "${MerchantID}") {\r\n        user_id\r\n        cpo_location_id\r\n        status\r\n    }\r\n}\r\n`,
        variables: {}
    });

    var requestOptions = {
        method: 'POST',
        headers: myHeaders,
        body: graphql,
        redirect: 'follow'
    };

    let response;

    try {
        // Initial request
        response = await fetch(
            `${process.env.REACT_APP_API_ADDRESS}/booking_merchants/graphql`,
            requestOptions
        );

        const responseData = await response.json();

        // Check if response is successful
        if (response.ok) {
            return responseData;  // If successful, return response data
        }
        // Check for 401 error within responseData.errors
        else if (responseData.errors && responseData.errors[0] && responseData.errors[0].status === 401) {
            console.log("Access token expired, attempting to refresh...");

            // Decrypt and retrieve refresh token from localStorage
            const saved_refreshToken = DecryptData('refresh_token');

            if (saved_refreshToken) {
                // Try to refresh the token
                const refreshResponse = await RefreshToken(saved_refreshToken);

                if (refreshResponse.status === 200) {
                    // Successfully refreshed the token, update the access token and retry the request
                    const newAccessToken = refreshResponse.data.access_token;
                    console.log("New access token: ", newAccessToken);

                    // Update headers with the new token
                    myHeaders.set("Authorization", "Bearer " + newAccessToken);
                    requestOptions.headers = myHeaders;

                    // Retry the request with the new token
                    response = await fetch(
                        `${process.env.REACT_APP_API_ADDRESS}/booking_merchants/graphql`,
                        requestOptions
                    );

                    const retryData = await response.json();

                    if (!response.ok) {
                        console.log(`Retry failed with status: ${response.status}`);
                        return null;
                    }
                    return retryData;  // Return the retry data if successful
                } else {
                    console.log("Failed to refresh token. Logging out...");
                    localStorage.clear();
                    return null;
                }
            } else {
                console.log("No refresh token available. Logging out...");
                localStorage.clear();
                return null;
            }
        } else {
            console.log(`Error occurred: ${response.status}`, responseData);
            return responseData;  // Return response data even if not 401 for further diagnostics
        }
    } catch (error) {
        console.log("Network error or other unexpected issue:", error);
        throw error;  // Rethrow the error or handle it accordingly
    }
};

export default DelAsFav;
