const CheckConnectorOverstaying = async (evse_uid, connector_id) => {
    const myHeaders = new Headers();
    myHeaders.append("Authorization", "Basic " + process.env.REACT_APP_API_KEY);
    myHeaders.append("Content-Type", "application/json");

    const requestOptions = {
        method: "GET",
        headers: myHeaders,
        redirect: "follow"
    };

    let response;

    try {
        response = await fetch(
            `${process.env.REACT_APP_API_ADDRESS}/booking_reservation/api/v1/check_connector_plugout_datetime/${evse_uid}/${connector_id}`,
            requestOptions
        );

        if (!response.ok) {
            // Handle specific error status codes
            if (response.status === 401) {
                //localStorage.clear();

            } else if (response.status === 404) {
                //localStorage.clear();

            } else {
                console.log(`Server error: ${response.status}`);
            }
        } else {
            return response.json()
        }
    } catch (error) {
        console.log("Network error or other unexpected issue:", error);
    }
}

export default CheckConnectorOverstaying