import RefreshToken from './RefreshToken';
import DecryptData from './DecryptData';

const SetAsFav = async (AccessToken, MerchantID) => {
    var myHeaders = new Headers();
    myHeaders.append("Accept", "application/json");
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("Authorization", "Bearer " + AccessToken);
    console.log(MerchantID);

    var graphql = JSON.stringify({
        query: `mutation AddToFavoriteLocation {
            addToFavoriteLocation(cpo_location_id: "${MerchantID}") {
                user_id
                cpo_location_id
                status
            }
        }`,
        variables: {}
    });

    var requestOptions = {
        method: 'POST',
        headers: myHeaders,
        body: graphql,
        redirect: 'follow'
    };

    let response;

    try {
        response = await fetch(
            `${process.env.REACT_APP_API_ADDRESS}/booking_merchants/graphql`,
            requestOptions
        );

        const responseData = await response.json();

        if (!response.ok) {
            // Handle GraphQL specific errors
            if (responseData.errors && responseData.errors[0].status === 401) {
                console.log("Access token expired, attempting to refresh...");

                // Decrypt and retrieve the refresh token from localStorage
                const saved_refreshToken = DecryptData('refresh_token');

                if (saved_refreshToken) {
                    // Try to refresh the token
                    const refreshResponse = await RefreshToken(saved_refreshToken);

                    if (refreshResponse.status === 200) {
                        // Successfully refreshed the token, update the access token and retry the request
                        const newAccessToken = refreshResponse.data.access_token;
                        console.log("New access token: ", newAccessToken);

                        // Update headers with the new token
                        myHeaders.set("Authorization", "Bearer " + newAccessToken);
                        requestOptions.headers = myHeaders;

                        // Retry the request with the new token
                        response = await fetch(
                            `${process.env.REACT_APP_API_ADDRESS}/booking_merchants/graphql`,
                            requestOptions
                        );

                        const retryData = await response.json();

                        if (!response.ok) {
                            console.log(`Retry failed with status: ${response.status}`);
                            return null;
                        }
                        return retryData;  // Return retry data if successful
                    } else {
                        console.log("Failed to refresh token. Logging out...");
                        localStorage.clear();
                        return null;
                    }
                } else {
                    console.log("No refresh token available. Logging out...");
                    localStorage.clear();
                    return null;
                }
            } else {
                console.log(`Error occurred: ${response.status}`, responseData);
                return responseData;  // Return response data even if not 401 for further diagnostics
            }
        } else {
            return responseData;  // Return successful response data
        }
    } catch (error) {
        console.log("Network error or other unexpected issue:", error);
        throw error;  // Rethrow the error or handle it accordingly
    }
};

export default SetAsFav;
