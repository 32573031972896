const TrySignUp = async (firstName, lastName, address, mobileNumber, email, username, plateNumber, brand, model) => {
    var myHeaders = new Headers();
    myHeaders.append("Accept", "application/json");
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("Authorization", "Basic " + process.env.REACT_APP_API_KEY);

    var raw = JSON.stringify({
        "first_name": firstName,
        "last_name": lastName,
        "address": address,
        "contact_number": mobileNumber,
        "email_address": email,
        "username": username,
        "vehicle_plate_number": plateNumber,
        "vehicle_brand": brand,
        "vehicle_model": model
    });

    var requestOptions = {
        method: 'POST',
        headers: myHeaders,
        body: raw,
        redirect: 'follow'
    };

    let response;

    try {
        response = await fetch(
            `${process.env.REACT_APP_API_ADDRESS}/registration/api/v1/register`,
            requestOptions
        );

        if (!response.ok) {
            // Handle specific error status codes
            if (response.status === 401) {
                console.log("Unauthorized: Token invalid.");
            } else {
                console.log(`Server error: ${response.status}`);
            }
        } else {
            console.log(response)
            return response.json()
        }
    } catch (error) {
        console.log("Network error or other unexpected issue:", error);
    }
};

export default TrySignUp;
